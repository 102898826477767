import { call, put, select, takeLatest } from "redux-saga/effects";
import { redeemGiftsAction, verifyCodeAction } from "../../constants";
import {
  redeemGiftFailure,
  redeemGiftSuccess
} from "../actions/redeem-gifts-actions";
import {
  verifyCodeFailure,
  verifyCodeSuccess
} from "../actions/verify-code-actions";
import {
  IRedeemGiftAction,
  IRedeemResponse,
  IVerifyCodeAction,
  IVerifyResponse,
  NetworkState
} from "../../types";
import {
  selectCallCenter,
  selectNetState,
  selectToken,
  selectCashierBranchId
} from "../selectors";
import { ON_OFFLINE, ON_ONLINE } from "../../constants/netEvents";
import { AxiosResponse } from "axios";
import { verifyCode } from "../../axios/verifyCode";
import { redeemCode } from "../../axios/redeemCode";
import { notify } from "react-notify-toast";
import { syncApp } from "../actions";
import i18n from "../../i18n/i18n";
import { codeRedeemedSuccessfully } from "../../i18n/strings/RedeemGifts";
import { REDEEM_GIFTS } from "../../i18n/strings/App";
import { selectCallCenterBranch } from "../../web-components/ManagePoints/lib";

function* redeemGiftSaga(action: IRedeemGiftAction) {
  const call_center: number | undefined = yield select(selectCallCenter);
  try {
    const token: string = yield select(selectToken);
    const netState: NetworkState = yield select(selectNetState);
    if (netState === ON_ONLINE) {
      let branch_id = yield select(selectCashierBranchId);
      if (call_center) {
        const branch = yield* selectCallCenterBranch();
        branch_id = branch.id;
      }
      const response: AxiosResponse<IRedeemResponse> = yield call(
        redeemCode,
        action.payload.code,
        token,
        call_center,
        branch_id
      );

      yield put(redeemGiftSuccess(response.data));
      yield put(syncApp());
      notify.show(
        i18n.t(codeRedeemedSuccessfully, {
          ns: REDEEM_GIFTS
        }),
        "success"
      );
      action.payload.cb();
    } else if (netState === ON_OFFLINE) {
      yield put(redeemGiftFailure(new Error("Offline")));
    }
  } catch (e) {
    yield put(redeemGiftFailure(e));
  }
}

function* verifyCodeSaga(action: IVerifyCodeAction) {
  try {
    const token: string = yield select(selectToken);
    const response: AxiosResponse<IVerifyResponse> = yield call(
      verifyCode,
      action.payload,
      token
    );
    if (response.data.status === 1) {
      yield put(verifyCodeSuccess(response.data));
    } else {
      yield put(verifyCodeFailure(new Error("Code is invalid!")));
    }
  } catch (e) {
    yield put(verifyCodeFailure(e));
  }
}

export function* watchRedeemGiftSaga() {
  yield takeLatest(redeemGiftsAction.requested, redeemGiftSaga);
}

export function* watchVerifyCodeSaga() {
  yield takeLatest(verifyCodeAction.requested, verifyCodeSaga);
}
