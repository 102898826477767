import Notification from "rc-notification";
import * as React from "react";
import * as styles from "./styles.module.sass";
import * as Strings from "../../i18n/strings/ManagePoints";
import { MANAGE_POINTS } from "../../i18n/strings/App";
import i18n, { getCurrentDirection } from "../../i18n/i18n";

let notification;
Notification.newInstance({}, n => (notification = n));

export interface ICustomNotificationsOptions {
  content: React.ReactElement<any>;
  key?: string;
  closable?: boolean;
  onClose?: () => void;
  duration?: number;
  style?: any;
  maxCount?: number;
  closeIcon?: React.ReactNode;
}

const notifyCustomContent = (options: ICustomNotificationsOptions) => {
  notification.notice({
    duration: 30,
    style: {
      position: "absolute",
      top: "25%",
      width: "100%",
      left: 0,
      transition: "all 1s",
      opacity: 1
    },
    onClose() {
      return
    },
    ...options
  });
};

export const ErrorNotificationUI: React.FC = ({ children }) => {
  return (
    <div
      className={`${styles.wrongCode} ${getCurrentDirection() === "rtl" ? styles.rtl : ""
        }`}
    >
      <img src={require("../../assets/icon-close.svg")} />
      <div className={styles.textWrapper}>
        <h3>{i18n.t(Strings.failed, { ns: MANAGE_POINTS })}</h3>
        <p>{children}</p>
      </div>
    </div>
  );
};

const error = (content: React.ReactChildren | string, duration = 3000) => {
  notifyCustomContent({
    duration: duration / 1000,
    content: <ErrorNotificationUI>{content}</ErrorNotificationUI>
  });
};

const success = (content: React.ReactChildren | string, duration = 1500) => {
  notifyCustomContent({
    duration: duration / 1000,
    content: (
      <div className={styles.rightCode}>
        <img src={require("../../assets/icon-right.svg")} />
        <div className={styles.textWrapper}>
          <h3>{i18n.t(Strings.success, { ns: MANAGE_POINTS })}</h3>
          <p>{content}</p>
        </div>
      </div>
    )
  });
};

export const customNotify = {
  error,
  success
};
