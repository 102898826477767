import { useState, useEffect } from "react";
import {
  IBranchWithPermissions,
  getAssignedBranches
} from "../../../axios/getAssignedBranches";
import { useProgressState } from "../../../../Utils/custom-hooks/useProgressState";
import { selectToken } from "../../../redux-store/selectors";
import { useSelect } from "../../../helpers/use-select";
import { OrderingStatus } from "../../../Pickup-App/components/AvailableRequests/AvailableRequestsModal";

export const branchHasPickUpEnabled = (branch: IBranchWithPermissions) =>
  branchHasSettingEnabled(branch, "pickup_status");
export const branchHasDeliveryEnabled = (branch: IBranchWithPermissions) =>
  branchHasSettingEnabled(branch, "delivery_status");

export const branchHasAnOrderingServiceEnabled = (
  branch: IBranchWithPermissions
) => {
  return branchHasDeliveryEnabled(branch) || branchHasPickUpEnabled(branch);
};

export const branchHasSettingEnabled = (
  branch: IBranchWithPermissions,
  setting: keyof IBranchWithPermissions["settings"]
) =>
  branch.settings &&
  branch.settings[setting] !== undefined &&
  branch.settings[setting] !== OrderingStatus.disabledIndefinitely;

export const useGetAssignedBranches = (tempToken?: string) => {
  const token = useSelect(selectToken);
  const hasPOS = useSelect(state => state.authReducer.hasPOS);
  const [assignedBranches, setAssignedBranches] = useState<
    IBranchWithPermissions[]
  >([]);
  const {
    setLoading,
    setSuccess,
    setFailure,
    ...progress
  } = useProgressState();

  useEffect(
    () => {
      setLoading();
      if (tempToken || token) {
        getAssignedBranches(tempToken || token)
          .then(({ data }) => {
            let branches = data.branches;
            if (hasPOS) {
              // POS branches can only access pickup nothing else
              // therefore if one of the branches doesn't have ordering privileges
              // they are not allowed to be chosen as the operating cashier's branch
              // a branch pickup privilege can only be given by a high ranking admin
              // in the brand dashboard
              branches = branches.filter(branchHasAnOrderingServiceEnabled);
            }
            setAssignedBranches(branches);
            setSuccess();
          })
          .catch(setFailure);
      }
    },
    [token, tempToken]
  );

  return {
    assignedBranches,
    ...progress
  };
};
