import * as constants from "../constants/socket-events";
import * as actions from "../redux/actions/socket-actions";
import { SocketEvent } from "./lib";

export default [
  new SocketEvent(
    constants.SOCKET_EVENT_RECEIVE_ORDERING_STATUS_CHANGE,
    actions.receiveStoreOrderingStatusChange
  )
];
