import { logCurrentAppVersion } from "../actions";
import { takeLatest, call, select } from "redux-saga/effects";
import { logCurrentAppVersionApi } from "../../axios/logCurrentAppVersion";
import { selectToken, selectCashierBranchId } from "../selectors";
import { union } from "ts-action";

const actionType = union({ logCurrentAppVersion });
function* logCurrentAppVersionSaga({
  payload: { app_version }
}: typeof actionType) {
  const token = yield select(selectToken);
  const branch_id = yield select(selectCashierBranchId);
  yield call(logCurrentAppVersionApi, {
    branch_id,
    token,
    app_version
  });
}

export function* watchLogCurrentAppVersionSaga() {
  yield takeLatest(logCurrentAppVersion.type, logCurrentAppVersionSaga);
}
