import { useState, useEffect, useCallback } from "react";
import { useSelect } from "../../src/helpers/use-select";
const events = ["touchend", "mousemove", "mousedown", "keypress"];
import throttle from "lodash/throttle";
import now from "lodash/now";

export const useInactive = (secondsToInactive = 120) => {
  const msToInactive = secondsToInactive * 1000;
  const [inactive, setInactive] = useState(false);
  const setAsActive = useCallback(
    throttle(
      () => {
        setLastActive(now());
        setInactive(false);
      },
      Math.floor(msToInactive / 2),
      { trailing: false }
    ),
    []
  );
  const setAsInactive = useCallback(() => {
    setInactive(true);
  }, []);
  const [lastActive, setLastActive] = useState(Date.now());
  const { isLoggedIn, hasSelectedBranch } = useSelect(
    state => state.authReducer
  );
  const isAuthenticated = isLoggedIn && hasSelectedBranch;

  useEffect(() => {
    if (isAuthenticated && !inactive) {
      const handle = setTimeout(setAsInactive, msToInactive);
      return () => {
        clearTimeout(handle);
      };
    }
    return undefined;
  }, [isAuthenticated, inactive, lastActive]);

  useEffect(() => {
    if (isAuthenticated) {
      events.forEach(eventName =>
        document.addEventListener(eventName, setAsActive)
      );
      return () => {
        events.forEach(eventName =>
          document.removeEventListener(eventName, setAsActive)
        );
      };
    }
    return;
  }, [isAuthenticated]);
  return {
    inactive
  };
};
