import { OrderStatus } from "../../constants/types";

export default {
  orderStatues: "حالة الطلب",
  orderCode: "كود الطلب",
  clientName: "اسم العميل",
  orderPrice: "سعر الطلب",
  rejected: "تم رفض الطلب",
  got_picked_up: "تم استلام الطلب",
  canceled_by_customer: "تم الإلغاء من طرف العميل",
  canceled_by_cashier: "تم إلغاء الطلب",
  canceled_by_courier: "تم إلغاء الطلب",
  canceled_by_agent: "تم إلغاء الطلب",
  allStatuses: "الكل",
  pastOrdersTitle: "الطلبات السابقة ({{count}})",
  searchByCodePlaceholder: "البحث بكود الطلب",
  from: "من",
  koinz: "كوينز",
  [OrderStatus.delivered]: "تم التوصيل",
  issuedAt: "تم الطلب في"
};
