import { takeLatest, select, call } from "redux-saga/effects";
import * as actions from "../actions/menuActions";
import { toggleCategoryAction } from "../../constants/actions";
import {
  selectToken,
  selectCashierBranchId,
  selectOrderingMenuDB
} from "../../../redux-store/selectors";
import { changeCategoryAvailability } from "../../axios/changeCategoryAvailability";
import { handleOptimism } from "./handleOptimism";

function* toggleCategorySaga(action) {
  const token = yield select(selectToken);
  const branch_id = yield select(selectCashierBranchId);
  const orderingMenuDBInstance = yield select(selectOrderingMenuDB);
  yield call(
    changeCategoryAvailability,
    token,
    branch_id,
    action.payload.id,
    action.payload.status
  );
  yield call(async () => {
    const value = await orderingMenuDBInstance?.categories.get(action.payload.id);
    await orderingMenuDBInstance?.categories.update(action.payload.id, { enabled: Number(!value.enabled) })
    await orderingMenuDBInstance?.items.where({ category_id: action.payload.id }).modify({ status: action.payload.status.includes("available") ? "enabled" : action.payload.status })
  })
  return action.payload;
}

export function* watchToggleCategorySaga() {
  yield takeLatest(
    toggleCategoryAction.requested,
    handleOptimism(toggleCategorySaga, {
      failure: actions.toggleCategoryFailure,
      success: actions.toggleCategorySuccess
    })
  );
}
