import { changeOrderingStatusAction } from "../../constants/actions";
import { put, select, call, takeLatest } from "redux-saga/effects";
import {
  changeOrderingStatusFailure,
  changeOrderingStatus,
  changeOrderingStatusSuccess
} from "../actions/orderingStatusActions";
import { union } from "ts-action";
import {
  selectToken,
  selectCashierBranchId
} from "../../../redux-store/selectors";
import { editOrderingStatus } from "../../axios/editOrderingStatus";
import { customPickupNotify } from "../../util/custom-pickup-notify";
import i18n from "../../../i18n/i18n";
import * as Strings from "../../i18n/strings/orderingStatus";

const actionType = union({ changeOrderingStatus });

function* changeOrderingStatusSaga(action: typeof actionType) {
  try {
    const token = yield select(selectToken);
    const branch_id = yield select(selectCashierBranchId);

    const { reason, status, interval } = action.payload;
    yield call(
      editOrderingStatus,
      token,
      branch_id,
      status,
      reason?.id,
      interval
    );
    yield put(
      changeOrderingStatusSuccess({
        pickup_status: status,
        delivery_status: status
      })
    );
    customPickupNotify.success(
      i18n.t(Strings.changedStatusSuccessful, { ns: "orderingStatus" })
    );
  } catch (e) {
    yield put(changeOrderingStatusFailure(""));
    customPickupNotify.error(
      i18n.t(Strings.somethingWentWrong, { ns: "orderingStatus" })
    );
  }
}

export function* watchChangeOrderingStatusSaga() {
  yield takeLatest(
    changeOrderingStatusAction.requested,
    changeOrderingStatusSaga
  );
}
