export default {
  logout: "تسجيل الخروج",
  controlPanel: "لوحة التحكم",
  menu: "قائمة الطعام",
  prevOrder: "الطلبات السابقة",
  howItWorks: "كيف يعمل",
  orderingSetup: "أعدادات الطلبات",
  contactSupport: "اتصل بالدعم",
  averagePreparingTime: "متوسط وقت التحضير",
  loyaltyManager: 'مدير النقاط'

};
