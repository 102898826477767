import axios from "./axios";
import * as Requests from "./requests";
import { ISyncActivity, ISyncSuccessResponse } from "../types/index";
import { AxiosReturn } from "./types";

export const sync = (
  last_updated_time: number,
  activities: ISyncActivity[],
  token: string,
  branch_id?: string
): AxiosReturn<ISyncSuccessResponse> => {
  return axios.post(
    Requests.sync,
    {
      last_updated_time,
      activities,
      branch_id
    },
    {
      headers: {
        token,
        branch_id
      }
    }
  );
};
