import { takeLatest, select, call, put, delay } from "redux-saga/effects";
import { contactSupportAction } from "../../constants/actions";
import {
  contactSupportSuccess,
  contactSupportFailure
} from "../actions/contact-support-action";
import {
  selectToken,
  selectCashierBranchId
} from "../../../redux-store/selectors";
import { assistanceRequest } from "../../axios/assistanceRequest";
function* contactSupportSaga() {
  try {
    const token = yield select(selectToken);
    const branch_id = yield select(selectCashierBranchId);
    const res = yield call(assistanceRequest, token, branch_id);
    yield put(contactSupportSuccess(res));
    yield delay(60 * 1000);
    yield put({ type: contactSupportAction.reset });
  } catch (e) {
    yield put(contactSupportFailure(e));
  }
}

export function* watchContactSupportSaga() {
  yield takeLatest(contactSupportAction.requested, contactSupportSaga);
}
