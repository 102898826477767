import { select, takeLatest, put, retry } from "redux-saga/effects";
import { union } from "ts-action";
import { selectToken } from "../../../redux-store/selectors";
import { getBranchByIdAPI } from "../../axios/orderingInfo";
import {
  getOrderingInfo,
  getOrderingInfoFailure,
  getOrderingInfoSuccess
} from "../actions/orderingInfo";

const getActionType = union({ getOrderingInfo });
function* getBranchOrderingInfoSaga(action: typeof getActionType) {
  try {
    const { branch_id } = action.payload;
    const token: string = yield select(selectToken);
    const res = yield retry(3, 1000, getBranchByIdAPI, token, branch_id);
    yield put(getOrderingInfoSuccess(res.data));
  } catch (e) {
    yield put(getOrderingInfoFailure());
  }
}
export function* watchBranchOrderingInfoSaga() {
  yield takeLatest(getOrderingInfo.type, getBranchOrderingInfoSaga);
}
