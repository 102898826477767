import { union } from "ts-action";
import {
  startOrderExitAnimation,
  changeOrderStatus
} from "../actions/ordersActions";
import { takeEvery, delay, put } from "redux-saga/effects";

const actionType = union({ startOrderExitAnimation });
function* orderExitAnimationSaga(action: typeof actionType) {
  yield delay(1000);
  yield put(
    changeOrderStatus({
      id: action.payload.order_id,
      newStatus: action.payload.status
    })
  );
}

export function* watchOrderExitAnimationSaga() {
  yield takeEvery(startOrderExitAnimation.type, orderExitAnimationSaga);
}
