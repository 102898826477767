import {ISyncSuccessResponse} from "../../../types";
import {CashierWebDB} from "../../../db/CashierWebDB";
import {call, put, select} from "redux-saga/effects";
import {selectDB} from "../../selectors";
import {syncAppSuccess, storeStore} from "../../actions";
import {IFailedActivity, IRedeemGiftInboxOperation, IRedeemRewardOperation} from "../../../data-model/types";

class TableMetaData<T> {
  constructor(public operation: string, public table: T) {
  }
}

const handleFailedOperations = (failedOperations: IFailedActivity[]): any[] => {
  return failedOperations.map(op => {
    const {data, message, timestamp,} = op;
    return {
      countryCode: data.countryCode,
      created_at: timestamp,
      price: data.receipt,
      id: data.id,
      receipt_code: data.receipt_code,
      phoneNumber: data.phoneNumber,
      customer: {
        phone_number: data.phoneNumber,
      },
      failed: 1,
      message,
    };
  })
};

const handleGiftInboxGifts = (giftInboxRedemptions: IRedeemGiftInboxOperation[]) : IRedeemRewardOperation[] => {
  return giftInboxRedemptions.map(item => ({
    ...item,
    item: item.gift,
  }))
}

export default function* handleSyncResponse(data: ISyncSuccessResponse) {
  const db: CashierWebDB = yield select(selectDB);


  const enqueueKey = {
    store: new TableMetaData('put', db.Store),
    branches: new TableMetaData('bulkPut', db.Branch),
    assigned_branches: new TableMetaData('bulkPut', db.AssignedBranch),
    categories: new TableMetaData('bulkPut', db.Category),
    items: new TableMetaData('bulkPut', db.StoreItem),
    cashiers: new TableMetaData('bulkPut', db.Cashier),
    customers: new TableMetaData('bulkPut', db.Customer),
    add_points_operations: new TableMetaData('bulkPut', db.AddPointsOperation),
    redeem_reward_operations: new TableMetaData('bulkPut', db.RedeemRewardOperation),
    failed_activities: new TableMetaData('bulkPut', db.AddPointsOperation),
    gift_operations: new TableMetaData('bulkPut', db.RedeemRewardOperation),
  };

  const fillDB = () => Promise.all(Object.keys(enqueueKey)
    .map((key) => {
      const keyMetadata = enqueueKey[key];
      const value = data[key];
      if (value) {
        if (key === 'cashiers') {
          return keyMetadata.table[keyMetadata.operation](value.filter(a => a.id))
        } else if (key === 'failed_activities') {
          return keyMetadata.table[keyMetadata.operation](handleFailedOperations(value));
        } else if (key === 'gift_operations') {
          return keyMetadata.table[keyMetadata.operation](handleGiftInboxGifts(value));
        }
        return keyMetadata.table[keyMetadata.operation](value);
      }
    }));
    if (data.store) {
      yield put(storeStore(data.store));
    }
  yield call(fillDB);
  yield put(syncAppSuccess({
    last_updated_time: data.last_updated_time
  }));
}
