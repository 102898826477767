export default {
  availableRequest: "الطلب متاح",
  unAvailableRequest: "الطلب متوقف",
  available: "متاح",
  unAvailable: "غير متاح",
  firstReason: "اختر السبب",
  secondReason: "نقص عماله",
  thirdReason: "تحديثات بقائمة الطعام",
  orderStatus: ":حالة الطلب",
  reason: ":السبب",
  offlineFor: "غير متاح لمدة:",
  other: "اخري",
  confirm: "تغيير الحالة",
  orderStatusTitle: "تغيير حالة الطلب",
  changedStatusSuccessful: "تم تغيير حالة الطلب بنجاح",
  somethingWentWrong: "حدث خطأ ما!",
  active: "مفعل",
  inactive: "غير مفعل",
  indefinitely: "الى اجل غير مسمى",
  until_next_day: "حتى نهاية اليوم",
  halfHour: "نصف ساعة",
  oneHour: "ساعة",
  threeHours: "ثلاث ساعات",
  eightHours: "ثماني ساعات",
  delivery: "توصيل",
  pickup: "استلام من الفرع"
};
