import {
  toggleModifierItemAction,
  getMenuAction,
  toggleCategoryAction,
  toggleItemAction,
  toggleModifierGroupAction,
  ACTION_SEARCH_FOR_MENU_ITEM
} from "../../constants/actions";
import { action, payload } from "ts-action";
import { IGetMenuResponse } from "../../axios/getMenu";
import { optAction } from "./lib";

export enum MenuEntityAvailability {
  enabled = "enabled",
  disabled = "disabled",
  sold_out_indefinitely = "sold_out_indefinitely",
  sold_out_all_day = "sold_out_all_day",
  hidden = "hidden"
}

export enum MenuEntitySwitching {
  enabled = "available",
  disabled = "disabled",
  sold_out_indefinitely = "sold_out_indefinitely",
  sold_out_all_day = "sold_out_all_day",
  hidden = "hidden"
}

export type errorMessage = string;

export const getMenu = action(getMenuAction.requested);
export const getMenuSuccess = action(
  getMenuAction.fulfilled,
  payload<IGetMenuResponse>()
);
export const getMenuFailure = action(
  getMenuAction.rejected,
  payload<errorMessage>()
);

// cats

interface IChangeStatusData<Switching = MenuEntityAvailability> {
  id: string;
  status: Switching;
}

export const toggleCategory = optAction(
  toggleCategoryAction.requested,
  payload<IChangeStatusData<MenuEntitySwitching>>()
);
export const toggleCategorySuccess = action(
  toggleCategoryAction.fulfilled,
  payload<IChangeStatusData<MenuEntitySwitching>>()
);
export const toggleCategoryFailure = action(
  toggleCategoryAction.rejected,
  payload<IChangeStatusData<MenuEntitySwitching>>()
);

// cats Items

export const toggleItem = optAction(
  toggleItemAction.requested,
  payload<IChangeStatusData<MenuEntitySwitching>>()
);
export const toggleItemSuccess = action(
  toggleItemAction.fulfilled,
  payload<IChangeStatusData<MenuEntitySwitching>>()
);
export const toggleItemFailure = action(
  toggleItemAction.rejected,
  payload<IChangeStatusData<MenuEntitySwitching>>()
);

// Modifier Group

export const toggleModifierGroup = optAction(
  toggleModifierGroupAction.requested,
  payload<IChangeStatusData<MenuEntitySwitching>>()
);
export const toggleModifierGroupSuccess = action(
  toggleModifierGroupAction.fulfilled,
  payload<IChangeStatusData<MenuEntitySwitching>>()
);
export const toggleModifierGroupFailure = action(
  toggleModifierGroupAction.rejected,
  payload<IChangeStatusData<MenuEntitySwitching>>()
);
// ModifierItem

export const toggleModifierItem = optAction(
  toggleModifierItemAction.requested,
  payload<IChangeStatusData<MenuEntitySwitching>>()
);
export const toggleModifierItemSuccess = action(
  toggleModifierItemAction.fulfilled,
  payload<IChangeStatusData<MenuEntitySwitching>>()
);
export const toggleModifierItemFailure = action(
  toggleModifierItemAction.rejected,
  payload<IChangeStatusData<MenuEntitySwitching>>()
);

export const searchForMenuItem = action(
  ACTION_SEARCH_FOR_MENU_ITEM,
  payload<string>()
);
