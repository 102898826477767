import { reducer, on } from "ts-action";
import { getOrderingInfoSuccess } from "../actions/orderingInfo";
import { getOrderingInfoAction } from "../../constants/actions";
import { withLoadingReducer } from "./withLoadingState";
import {
  DeliveryManagementStatus,
  IBranchOrdering
} from "../../../data-model/types";
import { DeliveryMethods } from "../../../Pickup-App/axios/changeDeliveryMethod";

export const branchOrderingInfoReducer = withLoadingReducer<IBranchOrdering>(
  reducer<IBranchOrdering>(
    [on(getOrderingInfoSuccess, (state, { payload }) => payload)],
    {
      created_at: 0,
      updadted_at: 0,
      updated_at: 0,
      delivery_method: DeliveryMethods.in_house_delivery,
      delivery_management_status: DeliveryManagementStatus.disabled,
      delivery_status: "enabled",
      id: "",
      lat: 0,
      lng: 0,
      pickup_prep_max: 0,
      pickup_prep_min: 0,
      pickup_status: "enabled"
    }
  ),
  getOrderingInfoAction
);
