import { reEnableOrderingStatusAction } from "../../constants/actions";
import { put, select, call, takeLatest } from "redux-saga/effects";
import {
  changeOrderingStatus,
  reEnableOrderingStatusFailure,
  reEnableOrderingStatusSuccess
} from "../actions/orderingStatusActions";
import { union } from "ts-action";
import {
  selectToken,
  selectCashierBranchId
} from "../../../redux-store/selectors";
import { customPickupNotify } from "../../util/custom-pickup-notify";
import i18n from "../../../i18n/i18n";
import * as Strings from "../../i18n/strings/orderingStatus";
import { reEnableOrderingStatus } from "../../axios/reEnableOrderingStatus";

const actionType = union({ changeOrderingStatus });

function* reEnableOrderingStatusSaga(action: typeof actionType) {
  try {
    const token = yield select(selectToken);
    const branch_id = yield select(selectCashierBranchId);
    const reEnable = yield call(
      reEnableOrderingStatus,
      token,
      branch_id,
    );
    yield put(reEnableOrderingStatusSuccess({ status: reEnable.data.status }));
    customPickupNotify.success(
      i18n.t(Strings.changedStatusSuccessful, { ns: "orderingStatus" })
    );
  } catch (e) {
    yield put(reEnableOrderingStatusFailure(""));
    customPickupNotify.error(
      i18n.t(Strings.somethingWentWrong, { ns: "orderingStatus" })
    );
  }
}

export function* watchReEnableOrderingStatusSaga() {
  yield takeLatest(
    reEnableOrderingStatusAction.requested,
    reEnableOrderingStatusSaga
  );
}
