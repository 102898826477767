import * as constants from "../../constants";
import {
  IAuthAction,
  ILoginSuccessAction,
  ISetSelectedCashierBranchAction
} from "../../types";
import { mutateState } from "../../helpers/mutate-state";
import { IBranchWithPermissions } from "../../axios/getAssignedBranches";
import { withLoadingReducer } from "../../Pickup-App/redux/reducers/withLoadingState";
import { CashierApps } from "../../components/AppSelector";
import { union } from "ts-action";
import { remoteLoginSuccess } from "../actions/auth-actions";

const TOKEN = "token";
const ID = "id";
const SELECTED_BRANCH = "selected_branch";
const IS_LOGGED_IN = "isLoggedIn";
const CALL_CENTER = "call_center";
const HAS_SELECTED_BRANCH = "hasSelectedBranch";
const HAS_POS = "hasPOS";
const SELECTED_APP = "selected_app";
const CASHIER_STORE_ID = "cashier_store_id";
export interface IAuthReducerState {
  [TOKEN]: string;
  [IS_LOGGED_IN]: boolean;
  [ID]: string;
  [SELECTED_BRANCH]: IBranchWithPermissions;
  [CALL_CENTER]: undefined | 1;
  [HAS_SELECTED_BRANCH]: boolean;
  [HAS_POS]: boolean;
  [SELECTED_APP]: CashierApps;
  [CASHIER_STORE_ID]: string;
  isLoggedInRemotely: boolean;
}

const initialState: IAuthReducerState = {
  [TOKEN]: "",
  [IS_LOGGED_IN]: false,
  [ID]: "",
  [SELECTED_BRANCH]: {} as any,
  [CALL_CENTER]: undefined,
  [HAS_SELECTED_BRANCH]: false,
  [HAS_POS]: false,
  [SELECTED_APP]: CashierApps.pickup,
  [CASHIER_STORE_ID]: "",
  isLoggedInRemotely: false
};

const remoteLoginActionType = union({ remoteLoginSuccess });
const authReducer = withLoadingReducer(
  (
    state: IAuthReducerState = initialState,
    action: IAuthAction | typeof remoteLoginActionType
  ): IAuthReducerState => {
    switch (action.type) {
      case constants.loginAction.fulfilled:
      case constants.switchAccountAction.fulfilled: {
        const value = mutateState(state, map => {
          const { payload } = action as ILoginSuccessAction;
          const callCenter = payload.call_center || 0;
          map.set(TOKEN, payload.token);
          map.set(ID, payload.id);
          map.set(IS_LOGGED_IN, true);
          map.set(CALL_CENTER, callCenter);
          map.set(HAS_POS, (typeof payload.store_pos === "string" && !payload.store_pos?.includes("other")));
          map.set(CASHIER_STORE_ID, payload.store_id);
        });
        return value;
      }
      case constants.logoutAction.fulfilled: {
        return initialState;
      }
      case constants.ACTION_SET_SELECTED_CASHIER_BRANCH: {
        return mutateState(state, map => {
          map.set(
            SELECTED_BRANCH,
            (action as ISetSelectedCashierBranchAction).payload
          );
          map.set(HAS_SELECTED_BRANCH, true);
        });
      }
      case constants.ACTION_SWITCH_APPS: {
        return mutateState(state, map => {
          map.set(SELECTED_APP, (action as any).payload);
        });
      }
      case remoteLoginSuccess.type: {
        const { payload } = action as typeof remoteLoginActionType;
        return mutateState(state, map => {
          map.set(TOKEN, payload.token);
          map.set(ID, payload.cashier_id);
          map.set(IS_LOGGED_IN, true);
          map.set(HAS_POS, Boolean(payload.store_pos));
          map.set(CASHIER_STORE_ID, payload.store_id);
          map.set("isLoggedInRemotely", true);
        });
      }
      default:
        return state;
    }
  },
  constants.loginAction
);

export default authReducer;
