import React from "react";
import * as styles from "./styles.module.sass";
import { addMinutes, formatDistanceStrict } from "date-fns";
import { IOrder } from "../../../redux/reducers/lib";
import { useDateFnsLocale } from "../../../hooks/useLocalizedFormat";
import * as Strings from "../../../i18n/strings/newOrder";
import { useTranslation } from "../../../hooks/useTranslation";
import { storedOrderType } from "../../../redux/reducers/getOrdersReducer";

const zeroOut = (num: number) => {
  return num < 0 ? 0 : num;
};

export const useCalculateEstimatedArrivalTime = (order: IOrder) => {
  const locale = useDateFnsLocale();

  const differenceInMs = Date.now() - order.created_at;
  const sinceCreationMinutes = Math.floor(differenceInMs / 1000 / 60);
  const estimatedMinutesTillArrival = zeroOut(
    Number(order.estimated_customer_arrival_interval?.toFixed() || 0) -
    sinceCreationMinutes
  );
  const nowDate = new Date();

  return formatDistanceStrict(
    addMinutes(nowDate, estimatedMinutesTillArrival),
    nowDate,
    {
      locale,
      unit: "minute",
      roundingMethod: "floor"
    }
  );
};

interface IPickupTimeInfoProps {
  order: storedOrderType;
}

const PickupTimeInfo: React.FC<IPickupTimeInfoProps> = props => {
  const { order } = props;
  const { t } = useTranslation("newOrder");

  return (
    <div className={styles.pickUpTimeWrapper}>
      {
        order.in_branch_order_type ? order.in_branch_order_type === "take_away" ? (
          <React.Fragment>
            <span className={styles.userName}>{t(Strings.userName)} </span>
            <span className={styles.info}>
              {order.customer.name}
            </span>
          </React.Fragment>

        ) : (<React.Fragment>
            <span className={styles.userName}>{t(Strings.tableNumber)}</span>
          <span className={styles.info}>
            {order.table_number}
          </span>
        </React.Fragment>) : (
          <React.Fragment>
              <span className={styles.userName}>{t(Strings.timer)} </span>
              <span className={styles.info}>
                {useCalculateEstimatedArrivalTime(order)}
              </span>
          </React.Fragment>
        )
      }

    </div>
  );
};

export default PickupTimeInfo;
