import { reducer, on } from "ts-action";
import {
  MenuEntityAvailability,
  MenuEntitySwitching,
  toggleModifierGroup
} from "../actions/menuActions";
import { toggleModifierGroupAction } from "../../constants/actions";
import { withLoadingReducer } from "./withLoadingState";
import { IModifierGroup } from "./lib";
export const modifierGroupReducer = withLoadingReducer<IModifierGroup>(
  reducer<IModifierGroup>(
    [
      on(toggleModifierGroup, (state, { payload: { status } }) => ({
        ...state,
        enabled: Number(!state.enabled),
        status:
          status === MenuEntitySwitching.enabled
            ? MenuEntityAvailability.enabled
            : MenuEntityAvailability[status]
      }))
    ],
    {
      ar_name: "",
      en_name: "",
      enabled: 0,
      id: "",
      status: MenuEntityAvailability.sold_out_indefinitely , 
      item_id : "" , 
    }
  ),
  toggleModifierGroupAction
);
