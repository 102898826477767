import { ON_OFFLINE, ON_ONLINE } from "../src/constants/netEvents";
import { NetworkState } from "../src/types";
import { imagesURL } from "../env/environment";

export const askPermission = (): Promise<any> => {
  // here we ask user for permission
  // it is a good idea to show the ask-permission pop up when the time is right
  // and user know why this happens and what can it change
  return new Promise((resolve, reject) => {
    const permissionResult = Notification.requestPermission(result => {
      resolve(result);
    });
    if (permissionResult) {
      permissionResult.then(resolve, reject);
    }
  }).then(permissionResult => {
    if (permissionResult !== "granted") {
      throw new Error("We weren't granted permission.");
    }
  });
};

type InternetConnectionListener = (isOnline: NetworkState) => any;
type InternetWatcherUnSubscriber = () => void;

export const watchInternet = (
  cb: InternetConnectionListener
): InternetWatcherUnSubscriber => {
  const watch = state => {
    cb(state.type);
  };
  window.addEventListener(ON_ONLINE, watch);
  window.addEventListener(ON_OFFLINE, watch);
  return () => {
    window.removeEventListener(ON_ONLINE, watch);
    window.removeEventListener(ON_OFFLINE, watch);
  };
};

export const sendNotification = (title: string, body: string): void => {
  // this is a simple function for sending a very basic notification
  navigator.serviceWorker
    .getRegistration()
    .then((registration: ServiceWorkerRegistration) => {
      const options: NotificationOptions = {
        body
      };
      registration.showNotification(title, options);
    });
};

export const prefixImageURL = url => imagesURL + url;
export const prefixOrdering = url => process.env.PICKUP_ENDPOINT + url;

export const calculatePoints = (price: number, convertingRatio: number) =>
  Math.floor(price * convertingRatio * 4);
