import { on, reducer } from "ts-action";
import * as actions from "../actions/orderingStatusActions";
import {
  receiveDeliveryStatusChange,
  receivePickupStatusChange,
  receiveStoreOrderingStatusChange
} from "../actions/socket-actions";
import { OrderingStatus } from "../../components/AvailableRequests/AvailableRequestsModal";
import { withLoadingReducer } from "./withLoadingState";
import { getOrderingStatusAction } from "../../constants/actions";

export interface IOrderingStatusState {
  storePickupStatus: OrderingStatus;
  branchPickupStatus: OrderingStatus;
  branchDeliveryStatus: OrderingStatus;
}

export const deriveOrderingStatus = ({
  branchDeliveryStatus,
  branchPickupStatus,
  storePickupStatus
}: IOrderingStatusState) => {
  const storeStatusPrecedes = storePickupStatus !== OrderingStatus.enabled;
  const disabledSet = new Set([
    OrderingStatus.disabledIndefinitely,
    OrderingStatus.pausedByCashier,
    OrderingStatus.pausedByBrand,
    OrderingStatus.pausedForOutsideWorkingHours
  ]);
  const pickupDisabled = disabledSet.has(branchPickupStatus);
  // const deliveryDisabled = disabledSet.has(branchDeliveryStatus);
  let statusToReturn = pickupDisabled
    ? branchDeliveryStatus
    : branchPickupStatus;
  statusToReturn =
    statusToReturn === OrderingStatus.pausedForDisconnection
      ? OrderingStatus.enabled
      : statusToReturn;
  return storeStatusPrecedes ? storePickupStatus : statusToReturn;
};

export const orderingStatus = withLoadingReducer<IOrderingStatusState>(
  reducer<IOrderingStatusState>(
    [
      on(actions.getOrderingStatusSuccess, (state, { payload }) => ({
        ...state,
        branchPickupStatus: payload.pickup_status,
        branchDeliveryStatus: payload.delivery_status
      })),
      on(actions.getStoreOrderingStatusSuccess, (state, { payload }) => ({
        ...state,
        storePickupStatus: payload
      })),
      on(actions.changeOrderingStatusSuccess, (state, { payload }) => ({
        ...state,
        branchPickupStatus: payload.pickup_status,
        branchDeliveryStatus: payload.delivery_status
      })),
      on(actions.reEnableOrderingStatusSuccess, (state, {}) => ({
        ...state,
        branchPickupStatus: OrderingStatus.enabled,
        branchDeliveryStatus: OrderingStatus.enabled
      })),
      on(receivePickupStatusChange, (state, { payload }) => {
        return {
          ...state,
          branchPickupStatus: payload.status
        };
      }),
      on(receiveDeliveryStatusChange, (state, { payload }) => {
        return {
          ...state,
          branchDeliveryStatus: payload.status
        };
      }),
      on(receiveStoreOrderingStatusChange, (state, { payload }) => {
        return {
          ...state,
          storePickupStatus: payload.pickup_status
        };
      })
    ],
    {
      storePickupStatus: OrderingStatus.enabled,
      branchPickupStatus: OrderingStatus.disabledIndefinitely,
      branchDeliveryStatus: OrderingStatus.disabledIndefinitely
    }
  ),
  getOrderingStatusAction
);
