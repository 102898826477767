export const availableTitle = "availableTitle";
export const unAvailableTitle = "unAvailableTitle";
export const avgTime = "avgTime";
export const contactSupport = "contactSupport";
export const label = "label";
export const from = "from";
export const to = "to";
export const minute = "minute";
export const save = "save";
export const cancel = "cancel";
export const pointingSystem = "pointingSystem";
export const requestsSystem = "requestsSystem";
export const current = "current";
export const transactions = "transactions";
export const contactSupportTitle = "contactSupportTitle";
export const min = "min";
export const time = "time";
export const pickupAverage = 'pickupAverage';
export const deliveryAverage = 'deliveryAverage';
export const koinz = "koinz";