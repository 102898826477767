import * as React from "react";
import styles from "./styles.module.sass";
import { getLocalizedCurrency } from "../../../../../../../web-components/AddPointsPhoneNumber/lib";

import i18n from "../../../../../../../i18n/i18n";
import { useSelect } from "../../../../../../../helpers/use-select";
import { useRtlClass } from "../../../../../../../lib";
import { useTranslatedName } from "../../../../../Menu/util";

export const useGetCurrency = () => {
  const store = useSelect(
    state => state.storeReducer.store || state.appReducer.store
  );
  return React.useMemo(() => {
    if (store && store.country_iso_code) {
      return getLocalizedCurrency(i18n.language, store.country_iso_code);
    }
    return getLocalizedCurrency(i18n.language, "eg");
  }, [i18n.language, store]);
};

export interface ISingleOrderModifierItemProps {
  name: string;
  en_name: string
  ar_name: string;
  price: number;
}

export const SingleOrderModifierItem: React.FC<ISingleOrderModifierItemProps> = props => {
  const {price} = props;
  const name = useTranslatedName(props)
  const CurrentCurrency = useGetCurrency();
  const rtl = useRtlClass(styles);
  return (
    <div className={`${styles.content} ${rtl}`}>
      <div className={styles.name}>{name}</div>
      <div className={styles.price}>
        <span>
          {CurrentCurrency} {price}
        </span>
      </div>
    </div>
  );
};
