import * as webWorkers from "../plugin-sw-precache/serviceWorker";
import * as React from "react";
import * as ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.sass";
import persistedStore, { isDev } from "./redux-store/configureStore";
import { Provider } from "react-redux";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n/i18n";
// import axios from "axios";
import "core-js/stable";
import "regenerator-runtime/runtime";
import AppSelector from "./components/AppSelector";
import Notifications from "react-notify-toast";
import { LoadingBar } from "react-redux-loading-bar";
import("./fonts.sass");
import JssProvider from "react-jss/lib/JssProvider";
import createGenerateClassName from "@material-ui/core/styles/createGenerateClassName";
import { HashRouter, Route } from "react-router-dom";
import firebase from "firebase/app";
import "firebase/firestore";
import { RemoteLogin } from "./components/RemoteLogin/index";
import { Switch } from "react-router";
import { PersistGate } from "redux-persist/integration/react";
import { RemoteLoginBanner } from "./components/RemoteLoginBanner/index";
import { RecoilRoot } from "recoil";
import { QueryClient, QueryClientProvider } from "react-query";
const firebaseConfig = {
  apiKey: "AIzaSyAyxR1qo-pYg2us7G2HOqg3sIy_VJLzI8E",
  authDomain: "cashier-web-app.firebaseapp.com",
  databaseURL: "https://cashier-web-app.firebaseio.com",
  projectId: "cashier-web-app",
  storageBucket: "cashier-web-app.appspot.com",
  messagingSenderId: "31502395959",
  appId: "1:31502395959:web:6c333127b787d077cc6db2"
};

firebase.initializeApp(firebaseConfig);

document.addEventListener("resume", event => {
  // The page has been unfrozen.
  // chrome will not change your page/tab to inactive page/tab
  return event;
});

if (!isDev) {
  import("@sentry/browser").then(({ init }) => {
    // let options: typeof sentry.default.
    init({
      // @ts-ignore
      dsn: "https://92c18af8e70743df9010a690dfdaf0ec@sentry.io/1362353"
    });
  });
}

const generateClassName = createGenerateClassName({
  dangerouslyUseGlobalCSS: true,
  productionPrefix: "cashier"
});

export async function initApp() {
  const { store, persistor } = persistedStore();
  const queryClient = new QueryClient();

  ReactDOM.render(
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <I18nextProvider i18n={i18n}>
            <RecoilRoot>
              <JssProvider generateClassName={generateClassName}>
                <>
                  <LoadingBar style={{ backgroundColor: "#f09440" }} />
                  <Notifications options={{ zIndex: 2000 }} />
                  <HashRouter>
                    <RemoteLoginBanner />
                    <Switch>
                      <Route
                        path={"/remote-login"}
                        exact={true}
                        component={RemoteLogin as any}
                      />

                      <AppSelector />
                    </Switch>
                  </HashRouter>
                </>
              </JssProvider>
            </RecoilRoot>
          </I18nextProvider>
        </PersistGate>
      </Provider>
    </QueryClientProvider>,
    document.getElementById("root") as HTMLDivElement
  );
}

initApp();
// register web workers
webWorkers.register();
