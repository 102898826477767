import { toggleOrderingStatusAction } from "../../constants/actions";
import { put, select, call, takeLatest } from "redux-saga/effects";
import {
  toggleOrderingStatus,
  toggleOrderingStatusFailure,
  toggleOrderingStatusSuccess
} from "../actions/orderingStatusActions";
import { union } from "ts-action";
import {
  selectToken,
  selectCashierBranchId
} from "../../../redux-store/selectors";
import { customPickupNotify } from "../../util/custom-pickup-notify";
import i18n from "../../../i18n/i18n";
import * as Strings from "../../i18n/strings/orderingStatus";
import { toggleOrderingStatusAPI } from "../../axios/changeOrderingStatusAPI";

const actionType = union({ toggleOrderingStatus });

function* toggleOrderingStatusSaga(action: typeof actionType) {
  try {
    const token = yield select(selectToken);
    const branch_id = yield select(selectCashierBranchId);
    const res = yield call(
      toggleOrderingStatusAPI,
      token,
      branch_id,
      action.payload.type,
      action.payload.service,
      action.payload.pause_until
    );
    yield put(toggleOrderingStatusSuccess({ status: res.data.status , pause_until: res.data.pause_until }));
    customPickupNotify.success(
      i18n.t(Strings.changedStatusSuccessful, { ns: "orderingStatus" })
    );
  } catch (e) {
    yield put(toggleOrderingStatusFailure(""));
    customPickupNotify.error(
      i18n.t(Strings.somethingWentWrong, { ns: "orderingStatus" })
    );
  }
}

export function* watchToggleOrderingStatusSaga() {
  yield takeLatest(
    toggleOrderingStatusAction.requested,
    toggleOrderingStatusSaga
  );
}
