import { takeLatest, put, select } from "redux-saga/effects";
import { ACTION_RECEIVE_NEW_ORDER } from "../../constants/actions";
import { customNotify } from "../../components/NewOrders/Notification";
import { union } from "ts-action";
import { receiveNewOrder } from "../actions/socket-actions";
import {
  changeOrdersGroupStatusFailure
} from "../actions/ordersActions";
import { calculateOrderTotal } from "../../components/Preparing/OrderDetails/PaymentDetails/index";
import { selectToken } from "../../../redux-store/selectors";
import { getReceiptBase64 } from "../../../axios/requests";

const path = require("../../assets/twin.aac").default;
const audio = new Audio(path);
const actionType = union({ receiveNewOrder });
function* newOrderArrivedSaga(action: typeof actionType) {
  try {
    const token = yield select(selectToken)
    audio.play();

    customNotify.info(
      `لقد وصلك طلب جديد بقيمة ${calculateOrderTotal(action.payload)}`
    );

     if ((window as any).ReactNativeWebView) {
       (window as any).ReactNativeWebView.postMessage(JSON.stringify({
         order_url: `${process.env.PICKUP_ENDPOINT}${getReceiptBase64}${action.payload.id}`,
         token: token
       }));
     }
  } catch (e) {
    yield put(changeOrdersGroupStatusFailure(e));
  }
}

export function* watchNewOrderArrivedSaga() {
  yield takeLatest(ACTION_RECEIVE_NEW_ORDER, newOrderArrivedSaga);
};