import React from "react";
import * as styles from "./styles.module.sass";
import { uDIC } from "../../../components/lib/useDefaultAndInsertedClassName";

interface IProps {
  label: string;
  className?: string;
  onClick?: (e: React.SyntheticEvent) => void;
  disabled?: boolean;
  type?: any;
}
const GenericButtonWithoutIcon: React.FC<IProps> = props => {
  return (
    <button
      {...props}
      className={uDIC(styles.genericButtonDefaults, props.className)}
    >
      {props.label}
    </button>
  );
};

export { GenericButtonWithoutIcon };
