import * as Strings from "../strings/loyalty";
export default {
  [Strings.pointsManager]: "مدير النقاط",
  [Strings.points]: "النقاط",
  [Strings.synced]: "تمت المزامنة",
  [Strings.syncing]: "جاري المزامنة ...",
  [Strings.allTransactions]: "كل العمليات",
  [Strings.codeIsNotValid]: "الكود غير صالح",
  [Strings.customerPhoneNumber]: "رقم هاتف العميل",
  [Strings.giftCode]: "كود الهدية",
  [Strings.optional]: "اختياري",
  [Strings.invoiceNumber]: "رقم الفاتورة",
  [Strings.invoice]: "الفاتورة",
  [Strings.giftFor]: "هدية ل",
  [Strings.redeemingGift]: "جاري تبديل الهدية ...",
  [Strings.redeemedSuccessfully]: "تم استبدال الهدية بنجاح",
  [Strings.redeemption]: "تبديل هدية",
  [Strings.verifyingCode]: "جاري التاكد من صحة الكود ...",
  [Strings.invoiceTotalOrder]: "القيمة الاجمالية للفاتورة",
  [Strings.noTransaction]: "لا يوجد عملية خاصة برقم",
  [Strings.redeemGift]: "تبديل الهدية",
  [Strings.redeemed]: "تم التبديل",
  [Strings.searchByPhoneNumber]: "ابحث باستخدام رقم الهاتف",
  [Strings.sentSuccessfully]: "تم ارسال النقاط بنجاح!",
  [Strings.searchForCountryNameOrCode]:
    "ابحث باستخدام اسم البلد او كود رقم الهاتف",
  [Strings.chooseCustomerCountry]: "اختر دولة العميل",
  [Strings.reset]: "Reset",
  [Strings.sendPoints]: "ارسل نقاط",
  [Strings.sendingPoints]: " جاري إرسال النقاط ...",
  [Strings.transactions]: "كل العمليات",
  [Strings.validateGiftCode]: "تاكد من صحة الكود",
  [Strings.pointPOSMode]: "نقطه",
};
