import axios from "./axios";
import { itemAvailability } from "./requests";
import { MenuEntitySwitching } from "../redux/actions/menuActions";

export const changeItemAvailability = (
  token: string,
  branch_id: string,
  id: string,
  availability: MenuEntitySwitching
) => {
  return axios.put(
    itemAvailability + "/" + id,
    { availability, branch_id },
    {
      headers: {
        token
      }
    }
  );
};
