import { select, takeLatest, put, call } from "redux-saga/effects";
import {
  selectCashierBranchId,
  selectToken
} from "../../../redux-store/selectors";
import { changeDeliveryStatus } from "../../axios/changeDeliveryMethod";
import {
  changeDeliveryMethod as changeDeliveryMethodActionFn,
  changeDeliveryMethodFailure,
  changeDeliveryMethodSuccess
} from "../actions/deliveryMethod";
import { changeDeliveryMethod as changeDeliveryMethodAction } from "../../constants/actions";
import { union } from "ts-action";
const actionType = union({ changeDeliveryMethodActionFn });

function* changeDeliveryMethod(action: typeof actionType) {
  try {
    const { deliveryMethod } = action.payload;
    const branch_id = yield select(selectCashierBranchId);
    const token = yield select(selectToken);
    yield call(changeDeliveryStatus, token, branch_id, deliveryMethod);
    yield put(changeDeliveryMethodSuccess(deliveryMethod));
  } catch (e) {
    yield put(changeDeliveryMethodFailure(e));
  }
}

export function* watchChangeDeliveryMethodSaga() {
  yield takeLatest(changeDeliveryMethodAction.requested, changeDeliveryMethod);
}
