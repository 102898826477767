import * as actions from "../actions/ordersActions";
import { union } from "ts-action";
import { select, call, takeEvery } from "redux-saga/effects";
import {
  selectCashierBranchId,
  selectToken
} from "../../../redux-store/selectors";
import { changeOrderStatus } from "../../axios/changeOrderStatus";
import { handleOptimism } from "./handleOptimism";
import { OrderStatus, PrivilegedStatus } from "../../constants/types";
import { cancelOrderApi } from "../../axios/cancelOrder";
import { markAsDeliveredAPI } from "../../axios/markAsDeliveredAPI";
import { markAsOutForDeliveryAPI } from "../../axios/markAsOutForDeliveryAPI";
import { dispatchOrderAPI } from "../../axios/dispatchOrderAPI";

const actionType = union({ a: actions.changeOrderStatus });

const altWebServices: {
  [x in PrivilegedStatus]: (
    token,
    data: { branch_id: string; order_id: string }
  ) => any
} = {
  [OrderStatus.delivered]: markAsDeliveredAPI,
  [OrderStatus.out_for_delivery]: markAsOutForDeliveryAPI,
  [OrderStatus.canceled_by_cashier]: cancelOrderApi,
  [OrderStatus.ready_for_courier_pickup]: dispatchOrderAPI
};
function* changeOrderStatusSaga(action: typeof actionType) {
  const token = yield select(selectToken);
  const branch_id = yield select(selectCashierBranchId);
  const { payload } = action;
  const status = payload.newStatus;
  const order_id = payload.id;
  if (altWebServices[status]) {
    yield call(altWebServices[status], token, { branch_id, order_id });
  } else {
    yield call(
      changeOrderStatus,
      token,
      branch_id,
      payload.id,
      payload.newStatus
    );
  }
  return action.payload;
}

export function* watchChangeOrderStatusSaga() {
  yield takeEvery(
    actions.changeOrderStatus.type,
    handleOptimism(changeOrderStatusSaga, {
      failure: actions.changeOrderStatusFailure,
      success: actions.changeOrderStatusSuccess
    })
  );
}
