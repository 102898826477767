export default {
  logout: "Logout",
  controlPanel: "Dashboard",
  menu: "Menu",
  prevOrder: "Orders History",
  howItWorks: "How it works",
  orderingSetup: "Settings",
  contactSupport: "Contact Support",
  averagePreparingTime: "Average Preparing Time",
  loyaltyManager: 'Loyalty Manager'
};
