import axios from "./axios";
import { modifierAvailability } from "./requests";
import { MenuEntitySwitching } from "../redux/actions/menuActions";

export const changeModifierItemsAvailability = (
  token: string,
  branch_id: string,
  id: string,
  availability: MenuEntitySwitching
) => {
  return axios.put(
    modifierAvailability + "/" + id,
    { branch_id, availability },
    {
      headers: {
        token
      }
    }
  );
};
