import axios from "./axios";
import { OrderStatus } from "../constants/types";

export const markOrdersReceivedSeenAPI = (
  token: string,
  orderIds: string[],
  status: OrderStatus.received | OrderStatus.seen
) => {
  return axios.put(
    `/api/cashier/orders/mark-as-${status}`,
    {
      orders_ids: orderIds
    },
    {
      headers: {
        token
      }
    }
  );
};
