import axios from "./axios";
import * as Requests from "./requests";
export const orderCancellation = (
  token: string,
  branch_id: string,
  order_id: string,
  reason_id?: number
) => {
  return axios.put(
    Requests.cancellation,
    { branch_id, reason_id, order_id },
    { headers: { token } }
  );
};
