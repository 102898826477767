import { changeDeliveryMethod as changeDeliveryMethodAction } from "../../constants/actions";
import { payload, action } from "ts-action";
import { DeliveryMethods } from "../../axios/changeDeliveryMethod";

export const changeDeliveryMethod = action(
  changeDeliveryMethodAction.requested,
  payload<{ deliveryMethod: DeliveryMethods }>()
);
export const changeDeliveryMethodSuccess = action(
  changeDeliveryMethodAction.fulfilled,
  payload<DeliveryMethods>()
);
export const changeDeliveryMethodFailure = action(
  changeDeliveryMethodAction.rejected,
  payload<Error>()
);
