import { OrderStatus, OrderType } from "../../constants/types";
import { fromUnixTime, differenceInSeconds } from "date-fns";
import { IRootReducerState } from "../../../redux-store/rootReducer";
import { MenuEntityAvailability } from "../actions/menuActions";
import { DeliveryMethods } from "../../axios/changeDeliveryMethod";

export type id = string;

export interface ICustomerStatus {
  id: string;
  has_arrived: boolean;
}
export interface IItems {
  id: string;
  primary_name: string;
  secondary_name: string;
  price: number;
  count: number;
  redeem_code: string;
  item_modifier_group: IItemModifierGroup[];
}

export interface IItemModifierGroup {
  primary_name: string;
  secondary_name: string;
  id: string;
  item_modifier: IItemModifier[];
}

export interface IItemModifier {
  id: string;
  primary_name: string;
  secondary_name: string;
  price: number;
}

export interface ICustomer {
  id: string;
  phone_number: string;
  name: string;
  image: string;
}

export interface ICategory {
  id: string;
  ar_name?: string;
  en_name?: string;
  enabled: number;
  status: MenuEntityAvailability;
}

export interface IItem {
  id: string;
  ar_name?: string;
  en_name?: string;
  price: string;
  description: string;
  image: string;
  status: MenuEntityAvailability;
  category_id: string;
}

export interface IModifierGroup {
  id: string;
  ar_name?: string;
  en_name?: string;
  enabled: number;
  status: MenuEntityAvailability;
  item_id: string;
}

export interface IModifier {
  id: string;
  name: string;
  ar_name: string;
  en_name: string;
  enabled: number;
  price: string;
  status: MenuEntityAvailability;
  modifier_group_id: string;
}

export interface IdentifiedModifierGroup {
  id: id;
  modifierItems: id[];
}
export interface IdentifiedItem {
  id: id;
  modifierGroups: IdentifiedModifierGroup[];
}
export interface IdentifiedCategory {
  id: id;
  items: IdentifiedItem[];
}

export enum OrderTimingStatus {
  justIn = "justIn",
  normal = "normal",
  overdue = "overdue"
}

export const getOrderTimingStatus = (orderTimeStamp: number) => {
  const now = new Date();
  const orderDate = fromUnixTime(orderTimeStamp / 1000);

  const diff = differenceInSeconds(now, orderDate);
  let state: OrderTimingStatus;

  state = diff < 30 ? OrderTimingStatus.justIn : OrderTimingStatus.normal;
  state = diff >= 180 ? OrderTimingStatus.overdue : state;

  return {
    state,
    diff
  };
};

export const totalLengths = (...args: any[][]) =>
  args.reduce((acc, next) => acc + next.length, 0);

export type rootSelector = <TSelected>(state: IRootReducerState) => any;
export enum DeliveredBy {
  shipox = "shipox",
  store = "store"
}

export type OrderingSource = "customer_app" | "white_label";
export interface IOrder {
  estimated_customer_arrival_interval?: number;
  order_code: string;
  delivery_fees: number;
  created_at: number;
  sent_at: number;
  total_invoices: number;
  total_invoices_without_promo_code?: number;
  id: string;
  status: OrderStatus;
  order_type: OrderType;
  sub_district_ar_name?: string;
  delivered_by: DeliveredBy;
  sub_district_en_name?: string;
  district_ar_name?: string;
  district_en_name?: string;
  customer: ICustomer;
  customer_status?: "arrived";
  payment_method: "cash";
  promo_code?: string;
  delivery_method?: DeliveryMethods;
  in_branch_order_type?: "take_away" | "dine_in";
  table_number?: any;
  dispatched_to_third_party_delivery?: number;
  ordering_source: OrderingSource;
}
