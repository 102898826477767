import { cancelOrderAction } from "../../constants/actions";
import { put, select, call, takeLatest } from "redux-saga/effects";
import { union } from "ts-action";
import { selectToken } from "../../../redux-store/selectors";
import { orderCancellation } from "../../axios/orderCancellation";
import {
  cancelOrder,
  cancelOrderFailure,
  cancelOrderSuccess
} from "../actions/ordersActions";
const actionType = union({ cancelOrder });

function* cancelOrderSaga(action: typeof actionType) {
  try {
    const token = yield select(selectToken);
    const { reason_id, branch_id, order_id } = action.payload;
    const res = yield call(
      orderCancellation,
      token,
      branch_id,
      order_id,
      reason_id
    );
    yield put(cancelOrderSuccess(res.data));
  } catch (e) {
    yield put(cancelOrderFailure(e));
  }
}

export function* watchCancelOrderSaga() {
  yield takeLatest(cancelOrderAction.requested, cancelOrderSaga);
}
