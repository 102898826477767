import { ToggleOrderingStatusType, IService } from "../../types";

export const getOrderDetails = (id: string) => `/api/cashier/orders/${id}`;
export const branchOrderingStatus = "/api/cashier/ordering-statuses";
export const storeOrderingStatus = "/api/cashier/store/pickup/status";
export const reEnableOrderingStatus = `/api/cashier/re-enable-ordering`;
export const pauseOrderingStatus = "/api/cashier/pause-ordering";
export const disableReasons = "/api/cashier/pickup/disable-reasons";
export const getOrders = "/api/cashier/today-orders";
export const modifierAvailability = "/api/cashier/modifier-availability";
export const modifierGroupAvailability =
  "/api/cashier/modifiers-group-availability";
export const itemAvailability = "/api/cashier/item-availability";
export const CategoryAvailability = "/api/cashier/category-availability";
export const getMenu = "/api/cashier/list-menu-items";
export const changeOrderStatus = "/api/cashier/orders/change-status";
export const cancelOrder = "/api/cashier/orders/cancel";
export const deliveryMethod = branch_id =>
  `/api/cashier/branch/delivery-method/${branch_id}`;
export const branchAvgPrepTime = (id: string) =>
  `/api/cashier/pickup/average-prep-time/${id}`;
export const orderAvgPrepTime = (id: string) =>
  `/api/cashier/pickup/order-average-prep-time/${id}`;

export const branchDeliveryAvgPrepTime = (id: string) => ` ${id}`;

export const getBranchOrderingInfo = (branch_id: string) =>
  `api/cashier/branch/${branch_id}`;

export const pastOrders = "/api/cashier/previous-orders";
export const store = "/api/cashier/store";
export const assistanceRequest = "/api/cashier/assistance-request";
export const getStatusReason = "/api/cashier/order-status-reasons";
export const rejectOrder = "/api/cashier/orders/reject";
export const cancellation = "/api/cashier/orders/cancel";
export const canChooseReason = (orderId: string) =>
  `/api/cashier/orders/${orderId}/can-choose-reason`;
export const getDeliveryReasonAPI =
  "/api/cashier/order-status-reasons/delivery";

export const markAsOutForDelivery =
  "/api/cashier/delivery-orders/mark-out-for-delivery";
export const markAsDelivered = "/api/cashier/delivery-orders/mark-delivered";

export const dispatchOrderForFleet = (orderId: string) =>
  `/api/cashier/delivery-orders/dispatch-to-dms/${orderId}`;

export const enableOrdering = (
  type: ToggleOrderingStatusType,
  service: IService
) => `/api/cashier/${type}-${service}`;
