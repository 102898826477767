import { fork, put, select, takeLatest } from "redux-saga/effects";
import { readNetChange } from "./channels/network-change";
import { networkChangeAction } from "../../constants";
import { syncApp } from "../actions";
import { INetworkChangeAction } from "../../types";
import { ON_ONLINE } from "../../constants/netEvents";
import { selectIsLoggedIn } from "../selectors";
import {
  getAppToShowFromState,
  CashierApps
} from "../../components/AppSelector";
import { getOrderingStatus } from "../../Pickup-App/redux/actions/orderingStatusActions";
import { getOrders } from "../../Pickup-App/redux/actions/ordersActions";
import { OrderStatus } from "../../Pickup-App/constants/types";

function* networkChangeSaga() {
  yield fork(readNetChange);
}

export function* watchNetworkChangeSaga() {
  yield takeLatest(networkChangeAction.requested, networkChangeSaga);
}

function* loyaltyHandler(action: INetworkChangeAction) {
  const isLoggedIn: boolean = yield select(selectIsLoggedIn);
  if (isLoggedIn && action.payload === ON_ONLINE) {
    yield put(syncApp());
  }
}

function* pickupHandler(action: INetworkChangeAction) {
  const isLoggedIn: boolean = yield select(selectIsLoggedIn);
  if (isLoggedIn && action.payload === ON_ONLINE) {
    // yield put(changeOrderingStatus({ status: OrderingStatus.enabled }));
    yield put(getOrderingStatus());
    yield put(getOrders([
      OrderStatus.sent,
      OrderStatus.received,
      OrderStatus.seen,
      OrderStatus.accepted,
      OrderStatus.ready_for_pickup,
    ]));
  }
}

function* networkChangeHandlerSaga(action: INetworkChangeAction) {
  const currentApp: { appToShow: CashierApps } = yield select(
    getAppToShowFromState
  );

  switch (currentApp.appToShow) {
    case CashierApps.PCLoyalty:
    case CashierApps.mobileLoyalty:
      yield* loyaltyHandler(action);
      break;
    case CashierApps.pickup:
      yield* pickupHandler(action);
      break;
    default:
      return;
  }
}

export function* watchNetworkChangeHandlerSaga() {
  yield takeLatest(networkChangeAction.fulfilled, networkChangeHandlerSaga);
}
