import * as React from "react";

interface IIconProps {
  className?: string;
  width?: string;
  height?: string;
}

export const ActiveIcon: React.FC<IIconProps> = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
    >
      <g className={className} fillRule="nonzero">
        <path d="M24.49 3.054l3.394 2.83a1.247 1.247 0 0 0 1.76-.16 1.25 1.25 0 0 0-.159-1.76l-3.394-2.83a1.25 1.25 0 1 0-1.601 1.92zM3.316 6.175c.282 0 .566-.095.8-.29l3.394-2.83a1.25 1.25 0 1 0-1.601-1.92l-3.394 2.83a1.25 1.25 0 0 0 .801 2.21zM26.43 6.82a14.639 14.639 0 0 0-9.18-4.267V1.25a1.25 1.25 0 1 0-2.5 0v1.303A14.639 14.639 0 0 0 5.57 6.82a14.653 14.653 0 0 0-4.32 10.43c0 3.94 1.534 7.644 4.32 10.43A14.653 14.653 0 0 0 16 32c3.172 0 6.195-.992 8.742-2.868a1.25 1.25 0 1 0-1.484-2.013A12.151 12.151 0 0 1 16 29.5c-6.755 0-12.25-5.495-12.25-12.25S9.245 5 16 5s12.25 5.495 12.25 12.25a12.26 12.26 0 0 1-1.51 5.897 1.25 1.25 0 1 0 2.19 1.206 14.767 14.767 0 0 0 1.82-7.103c0-3.94-1.534-7.644-4.32-10.43z" />
        <path d="M22.09 9.725a1.25 1.25 0 0 0-1.758.185l-3.942 4.87a2.503 2.503 0 0 0-2.89 2.47c0 1.379 1.122 2.5 2.5 2.5a2.503 2.503 0 0 0 2.333-3.396l3.942-4.87a1.25 1.25 0 0 0-.185-1.759z" />
      </g>
    </svg>
  );
};

export const InProgressIcon: React.FC<IIconProps> = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
    >
      <path
        className={className}
        fillRule="nonzero"
        d="M30.75 10.438h-2.498l3.559-5.654a1.25 1.25 0 0 0-2.123-1.32l-4.386 6.973H1.25c-.69 0-1.25.56-1.25 1.25v8.126c0 4.379 1.786 7.654 5.172 9.687H2.5a1.25 1.25 0 1 0 0 2.5h27a1.25 1.25 0 1 0 0-2.5h-2.692l.164-.1a1.25 1.25 0 0 0-1.32-2.123c-2.29 1.423-5.542 2.145-9.668 2.145-4.173 0-7.448-.739-9.734-2.196-2.523-1.608-3.75-4.033-3.75-7.413v-1.548c1.234.077 1.947.437 2.757.847.94.475 2.004 1.013 3.743 1.013 1.778 0 2.959-.559 4-1.051.935-.442 1.743-.824 3-.824 1.553 0 2.343.4 3.257.862.94.475 2.004 1.013 3.743 1.013 1.778 0 2.959-.559 4-1.051.805-.38 1.516-.716 2.5-.802v1.54c0 1.368-.21 2.603-.627 3.672a1.25 1.25 0 0 0 2.33.907c.529-1.36.797-2.9.797-4.578v-8.125c0-.69-.56-1.25-1.25-1.25zM16 15.75c-1.819 0-3.014.565-4.068 1.064-.92.435-1.715.811-2.932.811-1.143 0-1.792-.328-2.615-.744-.916-.464-2.038-1.03-3.885-1.119v-2.824h21.23l-2.682 4.265a15.757 15.757 0 0 1-.663-.322C19.39 16.377 18.15 15.75 16 15.75zm9.932 1.064c-.714.337-1.353.638-2.17.757l2.916-4.633H29.5v2.827c-1.544.091-2.614.597-3.568 1.049zM4.875 5.625c0-1.308.924-2.108 1.667-2.751.71-.615 1.146-1.023 1.146-1.624a1.25 1.25 0 1 1 2.5 0c0 1.774-1.162 2.78-2.01 3.514-.3.26-.803.695-.803.861a1.25 1.25 0 1 1-2.5 0zm6.688 0c0-1.3.902-2.1 1.628-2.742.695-.616 1.122-1.024 1.122-1.633a1.25 1.25 0 1 1 2.5 0c0 1.765-1.136 2.77-1.964 3.505-.313.277-.787.696-.787.87a1.25 1.25 0 1 1-2.5 0zm6.687 0c0-1.3.903-2.1 1.629-2.742C20.574 2.267 21 1.86 21 1.25a1.25 1.25 0 1 1 2.5 0c0 1.765-1.135 2.77-1.964 3.505-.313.277-.786.696-.786.87a1.25 1.25 0 1 1-2.5 0zm-1.937 17.25a1.25 1.25 0 1 1-2.5 0 1.25 1.25 0 0 1 2.5 0zm6.312.938a1.25 1.25 0 1 1 0 2.5 1.25 1.25 0 0 1 0-2.5zM10.562 23a1.25 1.25 0 1 1 0 2.5 1.25 1.25 0 0 1 0-2.5z"
      />
    </svg>
  );
};

export const ReadyIcon: React.FC<IIconProps> = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
    >
      <path
        className={className}
        fillRule="nonzero"
        d="M23.035 32H7.667c-2.757 0-5-2.243-5-5V5c0-2.757 2.243-5 5-5h15.368c2.757 0 5 2.243 5 5v15.75a1.25 1.25 0 0 1-2.5 0V5c0-1.378-1.122-2.5-2.5-2.5H7.667a2.503 2.503 0 0 0-2.5 2.5v22c0 1.378 1.121 2.5 2.5 2.5h15.368c1.378 0 2.5-1.122 2.5-2.5a1.25 1.25 0 1 1 2.5 0c0 2.757-2.243 5-5 5zM14.35 21.927a3.223 3.223 0 0 0 2.333-1.249l6.187-8.641a1.25 1.25 0 0 0-2.033-1.456l-6.162 8.607a.708.708 0 0 1-.492.244.703.703 0 0 1-.535-.191l-3.99-3.83a1.25 1.25 0 1 0-1.73 1.803l3.992 3.834a3.223 3.223 0 0 0 2.43.879z"
      />
    </svg>
  );
};
export const WifiIcon: React.FC<IIconProps> = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="120"
      height="120"
      viewBox="0 0 158 130"
      fill="#FFF"

    >
      <path
        className={className}
        fillRule="nonzero"
        d="M156.039 33.837C135.794 12.117 108.38.154 78.845.154c-29.533 0-56.947 11.963-77.191 33.683a6.16 6.16 0 0 0 9.012 8.4c17.888-19.193 42.101-29.763 68.181-29.763 26.078 0 50.291 10.57 68.18 29.763a6.143 6.143 0 0 0 4.507 1.96 6.16 6.16 0 0 0 4.505-10.36z M133.42 59.276c-14.964-15.078-34.346-23.381-54.575-23.38-20.233 0-39.615 8.303-54.575 23.38a6.16 6.16 0 1 0 8.745 8.678c12.63-12.729 28.906-19.738 45.83-19.739 16.92 0 33.197 7.01 45.83 19.74a6.141 6.141 0 0 0 4.373 1.82 6.16 6.16 0 0 0 4.372-10.5zM108.768 84.788c-8.014-7.94-19.501-12.872-29.98-12.872h-.453c-10.479 0-21.967 4.932-29.98 12.872a6.16 6.16 0 0 0 8.67 8.752c5.705-5.653 14.07-9.304 21.31-9.304h.452c7.24 0 15.606 3.652 21.31 9.304a6.16 6.16 0 1 0 8.67-8.752z"
      />
      <circle cx="78.538" cy="119.655" r="10.164" />

    </svg>
  );
};
export const NewOrdersEmptyIcon: React.FC<IIconProps> = ({ className, height = '150', width = '120' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 158 199"
      fill="#FFF"
      fill-rule="nonzero"
    >
      <path
        className={className}
        fillRule="nonzero"
        d="M119.121 145H95.88c-4.352 0-7.879 3.358-7.879 7.5 0 4.142 3.527 7.5 7.879 7.5h23.242c4.352 0 7.879-3.358 7.879-7.5 0-4.142-3.527-7.5-7.879-7.5zM119.121 98H95.88C91.527 98 88 101.358 88 105.5c0 4.142 3.527 7.5 7.879 7.5h23.242c4.352 0 7.879-3.358 7.879-7.5 0-4.142-3.527-7.5-7.879-7.5z M150.215 136.813c4.3 0 7.785-3.48 7.785-7.774V31.094C158 13.949 144.03 0 126.858 0H31.142C13.97 0 0 13.949 0 31.094v136.812C0 185.051 13.97 199 31.142 199h95.716C144.03 199 158 185.051 158 167.906c0-4.293-3.486-7.773-7.785-7.773-4.3 0-7.786 3.48-7.786 7.773 0 8.573-6.985 15.547-15.57 15.547H31.141c-8.586 0-15.571-6.974-15.571-15.547V31.094c0-8.573 6.985-15.547 15.57-15.547h95.717c8.586 0 15.571 6.974 15.571 15.547v97.945c0 4.293 3.486 7.774 7.786 7.774z M79.207 30.795a7.597 7.597 0 0 0-10.83 1.05L51.76 52.325l-6.557-6.857a7.595 7.595 0 0 0-10.877-.147c-3.044 3.018-3.109 7.978-.145 11.077l8.252 8.63A13.31 13.31 0 0 0 51.886 69c.294 0 .59-.01.885-.03 3.71-.252 7.173-2.08 9.515-5.019l17.951-22.127c2.707-3.336 2.245-8.273-1.03-11.028zM79.207 125.794a7.598 7.598 0 0 0-10.83 1.05l-16.621 20.488-6.568-6.83a7.595 7.595 0 0 0-10.878-.116c-3.035 3.027-3.086 7.986-.114 11.078l8.252 8.58c.063.066.127.13.192.194A13.31 13.31 0 0 0 51.886 164c.294 0 .59-.01.885-.03 3.709-.252 7.173-2.08 9.515-5.02l17.951-22.127c2.707-3.336 2.245-8.273-1.03-11.029zM119.121 50H95.88C91.527 50 88 53.358 88 57.5c0 4.142 3.527 7.5 7.879 7.5h23.242c4.352 0 7.879-3.358 7.879-7.5 0-4.142-3.527-7.5-7.879-7.5zM79.207 78.795c-3.275-2.756-8.124-2.286-10.83 1.05l-16.621 20.487-6.568-6.83a7.595 7.595 0 0 0-10.878-.116c-3.035 3.027-3.086 7.986-.114 11.078l8.252 8.58c.063.066.127.13.192.194A13.31 13.31 0 0 0 51.886 117c.294 0 .59-.01.885-.03 3.709-.252 7.173-2.08 9.515-5.02l17.951-22.127c2.707-3.335 2.245-8.273-1.03-11.028z"
      />

    </svg>
  );
};
