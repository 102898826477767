import axios from "./axios";
import * as request from "./requests";

export const dispatchOrderAPI = (
  token: string,
  data: { order_id: string; branch_id: string }
) => {
  return axios.post(
    request.dispatchOrderForFleet(data.order_id),
    {},
    {
      headers: {
        token
      }
    }
  );
};
