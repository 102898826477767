import axios from "./axios";
import { assignedBranches } from "./requests";
import { DeliveryManagementStatus, IBranch } from "../data-model/types";
import { OrderingStatus } from "../Pickup-App/components/AvailableRequests/AvailableRequestsModal";
import { DeliveryMethods } from "../Pickup-App/axios/changeDeliveryMethod";

export interface IBranchWithPermissions extends IBranch {
  settings: {
    pickup_status: OrderingStatus;
    delivery_status: OrderingStatus;
    delivery_method: DeliveryMethods;
    delivery_management_status: DeliveryManagementStatus;
  };
}

export const getAssignedBranches = (token: string) => {
  return axios.get<{ branches: IBranchWithPermissions[] }>(assignedBranches, {
    headers: {
      token
    }
  });
};
