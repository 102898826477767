import axios from "./axios";
import * as requests from "./requests";
import { OrderStatus } from "../constants/types";

export const changeOrderStatus = (
  token: string,
  branch_id: string,
  order_id: string,
  status: OrderStatus
) => {
  return axios.put(
    requests.changeOrderStatus,
    {
      branch_id,
      order_id,
      status
    },
    {
      headers: {
        token
      }
      // params: {
      //   branch_id
      // }
    }
  );
};
