import { getMenuAction } from '../../constants/actions'
import { select, call, put, takeLatest } from 'redux-saga/effects';
import * as actions from '../actions/menuActions';
import { selectToken, selectCashierBranchId, selectOrderingMenuDB } from '../../../redux-store/selectors';
import { getMenu, IGetMenuResponse } from '../../axios/getMenu';
import { formatCategories } from '../reducers/menuReducer';
import { clearOrderingMenuDB, orderingMenuDBSetter } from '../../../Pickup-App/helpers/orderingMenuDBHelpers';


export const TimeToExpire = 60 * 60 * 1000;
function* getMenuSaga() {
  try {
    const token = yield select(selectToken);
    const branch_id = yield select(selectCashierBranchId);
    const orderingMenuDBInstance = yield select(selectOrderingMenuDB);
    const lastHydrated = yield call(async () => {
      const data = await orderingMenuDBInstance?.schedulers.toArray();
      return data;
    });

    if (lastHydrated.length) {
      const { hydrated_at: lastHydratedValue, branch_id: lastBranchId } = lastHydrated[0];
      const isExpired: boolean = (Date.now() - lastHydratedValue) >= TimeToExpire;
      if (isExpired || lastBranchId !== branch_id) {
        yield call(clearOrderingMenuDB, orderingMenuDBInstance)
        const res = yield call(getMenu, token, branch_id);
        yield put(actions.getMenuSuccess(res.data))
        const { categoriesById,
          modifierGroupsById, modifierItemsById,
          itemsById } = yield call(formatCategories, res.data?.categories as IGetMenuResponse["categories"]);

        yield call(orderingMenuDBSetter, orderingMenuDBInstance, itemsById, categoriesById, modifierItemsById, modifierGroupsById, branch_id)
      } else {

        const categories = yield call(async () => {
          const value = await orderingMenuDBInstance?.categories.toArray();
          return value;
        });

        const items = yield call(async () => {
          const value = await orderingMenuDBInstance?.items.toArray();
          return value;
        });

        const groups = yield call(async () => {
          const value = await orderingMenuDBInstance?.modifiers_groups.toArray();
          return value;
        });

        const modifiers = yield call(async () => {
          const value = await orderingMenuDBInstance?.modifiers.toArray();
          return value;
        });

        const mappedMenu = {
          categories: categories.map(cat => {
            return { ...cat, items: items.filter(item => item.category_id === cat.id).map(item => ({ ...item, modifiers_groups: groups.filter(group => group.item_id === item.id).map(group => ({ ...group, modifiers: modifiers.filter(modifier => modifier.modifier_group_id === group.id) })) })) }
          })
        }
        yield put(actions.getMenuSuccess(mappedMenu))

      }
    } else {

      const res = yield call(getMenu, token, branch_id);
      yield call(clearOrderingMenuDB, orderingMenuDBInstance)
      yield put(actions.getMenuSuccess(res.data))
      const { categoriesById,
        modifierGroupsById, modifierItemsById,
        itemsById } = yield call(formatCategories, res.data?.categories as IGetMenuResponse["categories"]);

      yield call(orderingMenuDBSetter, orderingMenuDBInstance, itemsById, categoriesById, modifierItemsById, modifierGroupsById, branch_id)


    }
  }
  catch (e) {
    yield put(actions.getMenuFailure(''));
  }
};




export function* watchGetMenuSaga() {
  yield takeLatest(getMenuAction.requested, getMenuSaga);
}
