import { select, put, call, takeEvery } from "redux-saga/effects";
import * as actions from "../actions/menuActions";
import { toggleModifierItemAction } from "../../constants/actions";
import {
  selectToken, selectCashierBranchId, selectOrderingMenuDB
} from "../../../redux-store/selectors";
import { union } from "ts-action";
import { changeModifierItemsAvailability } from "../../axios/changeModifierItemsAvailability";

const actionType = union({ toggleItem: actions.toggleModifierItem });
function* toggleModifierItemsSaga(action: typeof actionType) {
  try {
    const token = yield select(selectToken);
    const branch_id = yield select(selectCashierBranchId);
    const orderingMenuDBInstance = yield select(selectOrderingMenuDB);
    yield call(
      changeModifierItemsAvailability,
      token,
      branch_id,
      action.payload.id,
      action.payload.status
    );
    yield put(actions.toggleModifierItemSuccess(action.payload));
    yield call(async () => {
      const value = await orderingMenuDBInstance?.modifiers.get(action.payload.id);
      await orderingMenuDBInstance?.modifiers.update(action.payload.id, { enabled: Number(!value.enabled) })
      
    })
  } catch (e) {
    yield put(actions.toggleModifierItemFailure(action.payload));
  }
}

export function* watchToggleModifierItemsSaga() {
  yield takeEvery(toggleModifierItemAction.requested, toggleModifierItemsSaga);
}
