import { all, spawn } from "redux-saga/effects";
import {
  watchNetworkChangeHandlerSaga,
  watchOpenDBSaga,
  watchSyncSuccessSaga,
  watchSyncSaga,
  watchSwitchAccountSaga,
  watchGetCountriesSaga,
  watchLoginSaga,
  watchLogoutSaga,
  watchActivitySage,
  watchLogAddPointsSaga,
  watchLogRedeemPointsSaga,
  watchManagePointsSaga,
  watchNetworkChangeSaga,
  watchOpenSelectCallCenterBranchSaga,
  watchRedeemGiftSaga,
  watchSearchCustomersSaga,
  watchGetAccountsSaga,
  watchVerifyCodeSaga,
  watchSwitchAppSaga
} from "./sagas";
import {
  watchGetOrderingStatusSaga,
  watchGetMenuSaga,
  watchChangeOrderingStatusSaga,
  watchToggleCategorySaga,
  watchToggleItemSaga,
  watchToggleModifierGroupSaga,
  watchToggleModifierItemsSaga,
  watchGetOrdersSaga,
  watchChangeOrderStatusSaga,
  watchChangeOrderTimingStatus,
  watchGetBranchAvgPrepTime,
  watchChangeBranchAvgPrepTime,
  watchReEnableOrderingStatusSaga,
  watchNewOrderArrivedSaga,
  watchGetStoreSaga,
  watchContactSupportSaga,
  watchBranchOrderingInfoSaga,
  watchChangeOrdersGroupStatusSaga,
  watchGetStatusReasonSaga,
  watchRejectOrderSaga,
  watchCancelOrderSaga,
  watchCanChooseReasonSaga,
  watchGetDeliveryReasonsSaga,
  watchToggleOrderingStatusSaga,
  watchChangeDeliveryMethodSaga, 
  watchOpenOrderingMenuDBSaga
} from "../Pickup-App/redux/sagas";
import { watchLogCurrentAppVersionSaga } from "./sagas/logCurrentAppVersionSaga";
import { checkForUpdateSaga } from "../util/update-handler";
import { watchRemoteLoginSaga } from "./sagas/remoteLoginSaga";

export default function* rootSaga() {
  yield spawn(checkForUpdateSaga);
  yield all([
    watchNetworkChangeHandlerSaga(),
    watchOpenDBSaga(),
    watchSyncSuccessSaga(),
    watchSyncSaga(),
    watchSwitchAccountSaga(),
    watchGetCountriesSaga(),
    watchLoginSaga(),
    watchLogoutSaga(),
    watchActivitySage(),
    watchLogAddPointsSaga(),
    watchLogRedeemPointsSaga(),
    watchManagePointsSaga(),
    watchNetworkChangeSaga(),
    watchOpenSelectCallCenterBranchSaga(),
    watchRedeemGiftSaga(),
    watchSearchCustomersSaga(),
    watchGetAccountsSaga(),
    watchVerifyCodeSaga(),
    watchGetOrderingStatusSaga(),
    watchGetMenuSaga(),
    watchChangeOrderingStatusSaga(),
    watchToggleCategorySaga(),
    watchToggleItemSaga(),
    watchToggleModifierGroupSaga(),
    watchToggleModifierItemsSaga(),
    watchGetOrdersSaga(),
    watchChangeOrderStatusSaga(),
    watchChangeOrderTimingStatus(),
    watchGetBranchAvgPrepTime(),
    watchChangeBranchAvgPrepTime(),
    watchReEnableOrderingStatusSaga(),
    watchNewOrderArrivedSaga(),
    watchSwitchAppSaga(),
    watchContactSupportSaga(),
    watchGetStoreSaga(),
    watchChangeOrdersGroupStatusSaga(),
    watchLogCurrentAppVersionSaga(),
    watchRemoteLoginSaga(),
    watchGetStatusReasonSaga(),
    watchRejectOrderSaga(),
    watchCancelOrderSaga(),
    watchCanChooseReasonSaga(),
    watchGetDeliveryReasonsSaga(),
    watchToggleOrderingStatusSaga(),
    watchBranchOrderingInfoSaga(),
    watchChangeDeliveryMethodSaga() , 
    watchOpenOrderingMenuDBSaga() , 
  ]);
}
