import { reducer, on } from "ts-action";
import {
  // getMenu,
  getMenuSuccess,
  // getMenuFailure,
  toggleModifierItem,
  toggleModifierItemFailure,
  toggleModifierItemSuccess,
  toggleCategoryFailure,
  toggleCategorySuccess,
  toggleCategory,
  toggleItem,
  toggleItemFailure,
  toggleItemSuccess,
  toggleModifierGroup,
  toggleModifierGroupFailure,
  toggleModifierGroupSuccess,
  searchForMenuItem
} from "../actions/menuActions";
import { withLoadingReducer, ILoadingState } from "./withLoadingState";
import { getMenuAction } from "../../constants/actions";
import { IGetMenuResponse } from "../../axios/getMenu";
import { modifierItemReducer } from "./modifierItemReducer";
import {
  IdentifiedCategory,
  ICategory,
  IItem,
  IModifierGroup,
  IModifier
} from "./lib";
import { categoryItemReducer } from "./categoryItemReducer";
import { itemReducer } from "./itemReducer";
import { modifierGroupReducer } from "./modifierGroupsReducer";
import { optimistic, OptimisticState } from "redux-optimistic-ui";
import { Reducer } from "redux";

type IModifierIds = string[]; 
export interface IMenu {
  categories: IdentifiedCategory[];
  shownCategories: IdentifiedCategory[];
  categoriesById: {
    [id: string]: ICategory & ILoadingState;
  };
  itemsById: {
    [id: string]: IItem & ILoadingState;
  };
  modifierGroupsById: {
    [id: string]: IModifierGroup & ILoadingState;
  };
  modifierItemsById: {
    [id: string]: IModifier & ILoadingState;
  };
  lastHydrated: number;
  modifierNamePriceMap: {
    [enNamAndPrice: string]: IModifierIds; 
  }
}

const initialState: IMenu = {
  categories: [],
  categoriesById: {},
  itemsById: {},
  modifierGroupsById: {},
  modifierItemsById: {},
  shownCategories: [],
  lastHydrated: Date.now(),
  modifierNamePriceMap: {},
};

// const actions = union({ getMenuSuccess, getMenuFailure });

const eLs = <T extends {}>(obj: T) => ({ ...obj, loadingStatus: undefined });

export const formatCategories = (
  categories: IGetMenuResponse["categories"]
): IMenu => {
  const categoriesById: IMenu["categoriesById"] = {};
  const itemsById: IMenu["itemsById"] = {};
  const modifierGroupsById: IMenu["modifierGroupsById"] = {};
  const modifierItemsById: IMenu["modifierItemsById"] = {};
  const categoriesIds = categories.map(cat => {
    categoriesById[cat.id] = categoryItemReducer(eLs(cat));
    return {
      id: cat.id,
      items: cat.items.map(item => {
        itemsById[item.id] = itemReducer(eLs({ ...item, category_id: cat.id }));
        return {
          id: item.id,
          modifierGroups: item.modifiers_groups.map(modifierGroup => {
            modifierGroupsById[modifierGroup.id] = modifierGroupReducer(
              eLs({ ...modifierGroup, item_id: item.id })
            );
            return {
              id: modifierGroup.id,
              modifierItems: modifierGroup.modifiers.map(modifier => {
                modifierItemsById[modifier.id] = modifierItemReducer(
                  eLs({ ...modifier, modifier_group_id: modifierGroup.id })
                );
                return modifier.id;
              })
            };
          })
        };
      })
    };
  });
  
  // storing similar identifier ids (similar in name and price)
  const modifierNamePriceMap = Object.values(modifierItemsById).reduce((prevValue, currentValue) => {
    prevValue[`${currentValue.en_name || currentValue.ar_name || currentValue.name}-${currentValue.price}`] = 
      [...(prevValue[`${currentValue.en_name || currentValue.ar_name || currentValue.name}-${currentValue.price}`] || []),currentValue.id] 
    return prevValue; 
  }, {});

  return {
    shownCategories: categoriesIds,
    categories: categoriesIds,
    categoriesById,
    itemsById,
    modifierGroupsById,
    modifierItemsById,
    lastHydrated: 0,
    modifierNamePriceMap,
  };
};

//
export const menuReducer = optimistic(
  withLoadingReducer(
    reducer<IMenu>(
      [
        on(
          getMenuSuccess,
          (state, { payload }): IMenu => {
            return {
              ...state,
              ...formatCategories(payload.categories)
            };
          }
        ),
        on(
          { toggleCategory, toggleCategoryFailure, toggleCategorySuccess },
          (state, action) => {
            const categoryItem = state.categoriesById[action.payload.id];
            return {
              ...state,
              categoriesById: {
                ...state.categoriesById,
                [categoryItem.id]: categoryItemReducer(categoryItem, action)
              }
            };
          }
        ),
        on(
          { toggleItem, toggleItemFailure, toggleItemSuccess },
          (state, action) => {
            const item = state.itemsById[action.payload.id];
            return {
              ...state,
              itemsById: {
                ...state.itemsById,
                [item.id]: itemReducer(item, action)
              }
            };
          }
        ),
        on(
          {
            toggleModifierGroup,
            toggleModifierGroupFailure,
            toggleModifierGroupSuccess
          },
          (state, action) => {
            const modifierGroup = state.modifierGroupsById[action.payload.id];
            return {
              ...state,
              modifierGroupsById: {
                ...state.modifierGroupsById,
                [modifierGroup.id]: modifierGroupReducer(modifierGroup, action)
              }
            };
          }
        ),
        on(
          {
            toggleModifierItem,
            toggleModifierItemFailure,
            toggleModifierItemSuccess
          },
          (state, action) => {
            const modifier = state.modifierItemsById[action.payload.id];
            return {
              ...state,
              modifierItemsById: {
                ...state.modifierItemsById,
                [modifier.id]: modifierItemReducer(modifier, action)
              }
            };
          }
        ),
        on(searchForMenuItem, (state, action) => {
          const { categories, itemsById } = state;
          const term = action.payload.trim().toLowerCase();
          if (!term) {
            return { ...state, shownCategories: categories };
          }
          const searchedItems = categories.map(cat => {
            return {
              ...cat,
              items: cat.items.filter(({ id }) => {
                const item = itemsById[id];
                const formattedEnItemName = item.en_name?.toLowerCase();
                const enNameMatches = formattedEnItemName?.includes(term);
                const arNameMatches = item.ar_name?.includes(term);
                return enNameMatches || arNameMatches;
              })
            };
          });
          const emptyCategoriesOut = searchedItems.filter(
            cat => cat.items.length
          );
          return { ...state, shownCategories: emptyCategoriesOut };
        })
      ],
      initialState
    ),
    getMenuAction
  )
) as Reducer<OptimisticState<IMenu & ILoadingState>>;
