export const sync = "/api/cashier/sync";
export const login = "/api/cashier/login";
export const logout = "/api/cashier/logout";
export const switchAccount = "/api/cashier/switch-account";
export const forgetPassword = "/api/cashier/forget-password";
export const getCustomerInfo = "/api/cashier/get-customer-info-by-phone-number";
export const validateRedeemCode = "/api/cashier/validate-redeem-code";
export const redeemReward = "/api/cashier/redeem-reward";
export const switchCashier = "/api/cashier/switch-cashier";
export const getCountries = "/api/getCountries";
export const getCashierBranches = "/api/cashier/get-cashier-branches";

export const assignedBranches = "/api/cashier/assigned-branches";
export const customerSupportNumber = "/api/customer-service-agent";

export const logCurrentAppVersion = "/api/cashier/app-version";
export const getReceiptBase64 = '/api/cashier/order-receipt/';
