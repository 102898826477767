import { reducer, on } from "ts-action";
import {
  MenuEntityAvailability,
  MenuEntitySwitching,
  toggleModifierItem
} from "../actions/menuActions";
import { toggleModifierItemAction } from "../../constants/actions";
import { withLoadingReducer } from "./withLoadingState";
import { IModifier } from "./lib";

export const modifierItemReducer = withLoadingReducer<IModifier>(
  reducer<IModifier>(
    [
      on(toggleModifierItem, (state, { payload: { status } }) => ({
        ...state,
        enabled: Number(!state.enabled),
        status:
          status === MenuEntitySwitching.enabled
            ? MenuEntityAvailability.enabled
            : MenuEntityAvailability[status]
      }))
    ],
    {
      ar_name: "",
      en_name: "",
      name: "",
      price: "",
      enabled: 0,
      id: "",
      status: MenuEntityAvailability.sold_out_indefinitely,
      modifier_group_id: "",
    }
  ),
  toggleModifierItemAction
);
