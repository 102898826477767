import React, { useCallback } from "react";
import * as styles from "./styles.module.sass";
import { useTranslation } from "../../../../../../hooks/useTranslation";
import * as Strings from "../../../../../../i18n/strings/newOrder";
import { GenericButtonWithoutIcon } from "../../../../../../Shared-Components/GenericButtonWithoutIcon";
import { hot } from "react-hot-loader";
import { useDispatch } from "react-redux";
import {
  cancelOrder,
  rejectOrders
} from "../../../../../../redux/actions/ordersActions";
import { OrderStatus, OrderType } from "../../../../../../constants/types";
import { storedOrderType } from "../../../../../../redux/reducers/getOrdersReducer";
import { reduxForm, Field, InjectedFormProps } from "redux-form";
import {
  RenderDropDownSelect,
  ISelectItemData
} from "../../../../../AvailableRequests/AvailableRequestsModal/RenderDropDownSelect";
import { useSelect } from "../../../../../../../helpers/use-select";
import { required } from "../../../../../../../redux-store/validators";
import { switchStringLang } from "../../../../NewOrderItem/DeliveryLocation";
import { LoadingStatus } from "../../../../../../redux/reducers/withLoadingState";
interface IProps {
  onClose: () => void;
  goToInitialChoices: () => void;
  orderId: string;
  orders: storedOrderType;
}
const CancellingOrder: React.FC<IProps & InjectedFormProps> = props => {
  const { t } = useTranslation("newOrder");
  const {
    reasons,
    reasonsById,
    loadingStatus: pickupLoadingStatus
  } = useSelect(state => state.getStatusReasonReducer);
  const {
    deliveryReasons,
    deliveryReasonsById,
    loadingStatus: deliveryLoadingState
  } = useSelect(state => state.getDeliveryReasonsReducer);

  const { selected_branch } = useSelect(state => state.authReducer);
  const { orders, onClose, goToInitialChoices: hideAll, invalid } = props;
  const { status, id, order_type } = orders;
  const orderIsDelivery = order_type === OrderType.delivery;
  const reasonsCol = orderIsDelivery ? deliveryReasonsById : reasonsById;
  const loadingStatus = orderIsDelivery
    ? deliveryLoadingState
    : pickupLoadingStatus;
  const isLoadingReasons = loadingStatus === LoadingStatus.loading;
  const shouldDisableSubmitting = isLoadingReasons;
  const reasonsIds = orderIsDelivery ? deliveryReasons : reasons;
  const rejectionOrCancellationReasons = React.useMemo(
    () => {
      return reasonsIds.map(
        (reasonId) => {
          return reasonsCol[reasonId];
        }
      ).filter((item: any) => item.is_deleted === 0).map(
        (reason): ISelectItemData => {
          return {
            value: reason.id,
            label: switchStringLang(reason.ar_text, reason.en_text)
          };
        }
      );
    
    },
    [reasonsCol, reasonsIds]
  );

  const dispatch = useDispatch();
  const handleCancellingOrder = useCallback(
    values => {
      const payload = {
        branch_id: selected_branch.id,
        order_id: id,
        ...values
      };
      const { seen, sent, received } = OrderStatus;
      const isReject =
        status === seen || status === received || status === sent;
      const nextStateAction = isReject ? rejectOrders : cancelOrder;
      dispatch(nextStateAction(payload));
      onClose();
    },
    [id, selected_branch, status, onClose]
  );
  return (
    <div className={styles.wrapper}>
      <img
        onClick={hideAll}
        src={require("../../../../../../assets/close.svg")}
      />
      <div className={styles.titleWrapper}>
        <span className={styles.title}>{t(Strings.rejectionReason)}</span>
        <span className={styles.warning}>{t(Strings.warning)}</span>
      </div>

      <form
        style={{ width: "100%" }}
        onSubmit={props.handleSubmit(handleCancellingOrder)}
      >
        <Field
          component={RenderDropDownSelect}
          label={t(Strings.reason)}
          options={rejectionOrCancellationReasons}
          name={"reason_id"}
          validate={[required]}
        />
        <div className={styles.btnWrapper}>
          <GenericButtonWithoutIcon
            type={"submit"}
            disabled={invalid || shouldDisableSubmitting}
            className={styles.btn}
            label={t(Strings.cancellingOrder)}
          />
        </div>
      </form>
    </div>
  );
};

export default reduxForm<{}, IProps>({
  form: "rejectionForm"
})(hot(module)(CancellingOrder as any));
