import { on, reducer } from "ts-action";
import {
  MenuEntityAvailability,
  MenuEntitySwitching,
  toggleItem
} from "../actions/menuActions";
import { toggleItemAction } from "../../constants/actions";
import { withLoadingReducer } from "./withLoadingState";
import { IItem } from "./lib";

export const itemReducer = withLoadingReducer<IItem>(
  reducer<IItem>(
    [
      on(toggleItem, (state, { payload }) => ({
        ...state,
        status:
          payload.status === MenuEntitySwitching.enabled
            ? MenuEntityAvailability.enabled
            : MenuEntityAvailability[payload.status]
      }))
    ],
    {
      ar_name: "",
      description: "",
      en_name: "",
      status: MenuEntityAvailability.sold_out_indefinitely,
      id: "",
      image: "",
      price: "",
      category_id: ""
    }
  ),
  toggleItemAction
);
