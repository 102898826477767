import { DeliveryMethods } from "../../../Pickup-App/axios/changeDeliveryMethod";
import { reducer, on } from "ts-action";
import {
  changeDeliveryMethodSuccess,
  changeDeliveryMethod,
  changeDeliveryMethodFailure
} from "../actions/deliveryMethod";
import { changeDeliveryMethod as changeDeliveryMethodAction } from "../../constants/actions";
import { withLoadingReducer } from "./withLoadingState";

interface IState {
  delivery_method: DeliveryMethods;
}
export const changeDeliveryMethodReducer = withLoadingReducer<IState>(
  reducer<IState>(
    [
      on(changeDeliveryMethod, (state, { payload }) => ({
        delivery_method: payload.deliveryMethod
      })),
      on(changeDeliveryMethodSuccess, (state, { payload }) => ({
        delivery_method: payload
      })),
      on(changeDeliveryMethodFailure, state => ({
        delivery_method:
          state.delivery_method === DeliveryMethods.in_house_delivery
            ? DeliveryMethods.third_party_delivery
            : DeliveryMethods.in_house_delivery
      }))
    ],
    {
      delivery_method: DeliveryMethods.in_house_delivery
    }
  ),
  changeDeliveryMethodAction
);
