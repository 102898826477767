import Pusher from "pusher-js";

export const initSocket = (token: string, branch_id: string) => {
  const socket = new Pusher(process.env.PUSHER_API_KEY as string, {
    authEndpoint: process.env.PICKUP_ENDPOINT + '/api/pusher/cashier/auth',
    auth: {
      headers: {
        token,
      },
      params: {
        branch_id
      }
    },
    cluster: "eu"
  });

  // socket.subscribe(`presence-cashier_orders.${branch_id}`);
  return socket;
};
