export default {
  availableRequest: "Ordering on",
  unAvailableRequest: "Ordering off",
  available: "On",
  unAvailable: "Off",
  orderStatus: "Ordering Status:",
  reason: "Reasons:",
  other: "other",
  confirm: "Change the status",
  orderStatusTitle: "Change Ordering Status",
  changedStatusSuccessful: "Ordering status changed successfully",
  somethingWentWrong: "Something went wrong!",
  offlineFor: "Ordering is off for:",
  delivery: "delivery",
  pickup: "pickup",
  active: "Active",
  inactive: "Inactive",
  indefinitely: "Indefinitely",
  until_next_day: "Until next day",
  halfHour: "30 min",
  oneHour: "1 hour",
  threeHours: "3 hours",
  eightHours: "8 hours"
};
