import axios from "./axios";
import * as Requests from "./requests";

export const assistanceRequest = (token: string, branch_id: string) => {
  return axios.post(
    Requests.assistanceRequest,
    { branch_id },
    {
      headers: {
        token
      }
    }
  );
};
