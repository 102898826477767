import {ICashierFormState, IRootReducerState} from "./rootReducer";
import {NetworkState} from "../types/index";
import {ICashier} from "../data-model/types";

export const selectLastUpdatedTime = (state: IRootReducerState) => state.syncReducer.last_updated_time;
export const selectToken = (state: IRootReducerState) => state.authReducer.token;
export const selectID = (state: IRootReducerState) => state.authReducer.id;
export const selectDB = (state: IRootReducerState) => state.dbReducer.db;
export const selectOrderingMenuDB = (state: IRootReducerState) => state.orderingMenuDBReducer.db;

export const selectSyncState = (state: IRootReducerState): 0 | 1 | -1 => state.syncReducer.syncState;
export const selectNetState = (state: IRootReducerState): NetworkState => state.networkStateReducer.networkState;
export const selectForm = (state: IRootReducerState): ICashierFormState => state.form;
export const selectSwitchForm = (state: IRootReducerState): ICashierFormState => state.form;
export const selectIsLoggedIn = (state: IRootReducerState): boolean => state.authReducer.isLoggedIn;
export const selectLogPage = (state: IRootReducerState): number => state.logAddPointsReducer.pageNumber;
export const selectLogPage2 = (state: IRootReducerState): number => state.logRedeemPointsReducer.pageNumber;
export const selectCashier = (state: IRootReducerState): ICashier => state.switchAccountReducer.cashier;
export const selectCallCenter = (state: IRootReducerState): undefined | 1 => state.authReducer.call_center;
export const selectCashierBranchId = (state: IRootReducerState): string => state.authReducer.selected_branch.id;
