import axios from "./axios";
import * as requests from "./requests";

export enum DeliveryMethods {
  in_house_delivery = "in_house_delivery",
  third_party_delivery = "third_party_delivery"
}

export const changeDeliveryStatus = (
  token: string,
  branch_id: string,
  delivery_method
) => {
  return axios.put(
    requests.deliveryMethod(branch_id),
    {
      delivery_method
    },
    {
      headers: {
        token
      }
    }
  );
};
