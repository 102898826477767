import { useTranslation } from "../../hooks/useTranslation";
import { useMemo } from "react";
import { useDispatch } from "react-redux";
import React from "react";
import { MarkEntitySoldOutContext } from "./MarkItemSoldOutPopup";
import { MenuEntitySwitching } from "../../redux/actions/menuActions";
import { ActionCreator } from "ts-action";

export interface IBasicTranslatedObject {
  ar_name?: string;
  en_name?: string;
}

const languageMapping: { [x: string]: keyof IBasicTranslatedObject } = {
  ar: "ar_name",
  en: "en_name"
};

export const useTranslatedName = <
  TranslatedItem extends IBasicTranslatedObject
>(
  item: TranslatedItem
) => {
  const { i18n } = useTranslation();
  const currentLanguage = useMemo(() => i18n.language.split("-")[0], [
    i18n.language
  ]);
  return useMemo(
    () => {
      const mappedName = languageMapping[currentLanguage];

      return item[mappedName] || item.en_name || item.ar_name;
    },
    [currentLanguage, item]
  );
};

export const useHandleMenuEnabled = (
  parentEnabled: boolean,
  selfEnabled: boolean,
  id: string,
  actionCreator: ActionCreator<any, any>
) => {
  const dispatch = useDispatch();
  const { open } = React.useContext(MarkEntitySoldOutContext);

  const dispatchAction = React.useCallback(
    (status: MenuEntitySwitching) => dispatch(actionCreator({ id, status })),
    [id]
  );

  return React.useCallback(
    () => {
      if (!parentEnabled) {
        return;
      }
      if (!selfEnabled) {
        dispatchAction(MenuEntitySwitching.enabled);
      } else {
        open(status => {
          dispatchAction(status);
        });
      }
    },
    [parentEnabled, selfEnabled, dispatchAction, open]
  );
};

export const useHandleModifierGroupEnabled = (
  parentEnabled: boolean,
  selfEnabled: boolean,
  id: string,
  actionCreator: ActionCreator<any, any>
) => {
  const dispatch = useDispatch();
  const { openModifierGroup } = React.useContext(MarkEntitySoldOutContext);

  const dispatchAction = React.useCallback(
    (status: MenuEntitySwitching) => dispatch(actionCreator({ id, status })),
    [id]
  );

  return React.useCallback(
    () => {
      if (!parentEnabled) {
        return;
      }
      if (!selfEnabled) {
        dispatchAction(MenuEntitySwitching.enabled);
      } else {
        openModifierGroup(status => {
          dispatchAction(status);
        });
      }
    },
    [parentEnabled, selfEnabled, dispatchAction, openModifierGroup]
  );
};

export const useHandleModifierItemEnabled = (
  parentEnabled: boolean,
  selfEnabled: boolean,
  id: string,
  actionCreator: ActionCreator<any, any>,
  similarModifiersInNameAndPrice: string[],
  isUniqueModifierItem: boolean
) => {
  const dispatch = useDispatch();
  const { openModifierItem, openDisableEnableAllOrOne } = React.useContext(
    MarkEntitySoldOutContext
  );

  const dispatchAction = (status: MenuEntitySwitching, modItemId?: string) =>
    dispatch(actionCreator({ id: modItemId || id, status }));

  return React.useCallback(
    () => {
      if (!parentEnabled) {
        return;
      }
      if (isUniqueModifierItem) {
        if (!selfEnabled) {
          dispatchAction(MenuEntitySwitching.enabled);
        } else {
          openModifierItem(status => {
            dispatchAction(status);
          });
        }
        // element is not uniqe (multiple mod items with the same name and price)
      } else {
        if (!selfEnabled) {
          openDisableEnableAllOrOne(
            similarModifiersInNameAndPrice.length,
            "enable",
            (grouping) => {
              if (grouping === "single") {
                dispatchAction(MenuEntitySwitching.enabled);
              } else if (grouping === "all") {
                similarModifiersInNameAndPrice.forEach(modifierItemId => {
                  dispatchAction(MenuEntitySwitching.enabled, modifierItemId);
                });
              }
            }
          );
        } else {
          openModifierItem(status => {
            openDisableEnableAllOrOne(
              similarModifiersInNameAndPrice.length,
              "disable",
              (grouping) => {
                if (grouping === "single") {
                  dispatchAction(status);
                } else if (grouping === "all") {
                  similarModifiersInNameAndPrice.forEach(modifierItemId => {
                    dispatchAction(status, modifierItemId);
                  });
                }
              }
            );
          });
        }
      }
    },
    [parentEnabled, selfEnabled, dispatchAction, openModifierItem]
  );
};
