import * as styles from "./styles.module.sass";
import * as React from "react";
import {
  reduxForm,
  Field,
  InjectedFormProps,
  formValueSelector
} from "redux-form";
import Modal from "@material-ui/core/Modal";
import { ISelectItemData, RenderDropDownSelect } from "./RenderDropDownSelect";
import { useTranslation } from "../../../hooks/useTranslation";
import * as Strings from "../../../i18n/strings/orderingStatus";
import {
  // useGetDisableReasons,
  IReason
} from "../../../hooks/useGetDisableReasons";
import { useRtlClass } from "../../../../lib";
import { useDispatch } from "react-redux";
import { hot } from "react-hot-loader";
import { required } from "../../../../redux-store/validators";
import {
  changeOrderingStatus,
  reEnableOrderingStatus
} from "../../../redux/actions/orderingStatusActions";
// import { useSelect } from "../../../../helpers/use-select";
import { connect } from "react-redux";
import { IRootReducerState } from "../../../../redux-store/rootReducer";

interface IProps {
  open: boolean;
  onClose: () => void;
  onChange: (item: ISelectItemData<string>) => void;
  selectedStatus: OrderingStatus;
}

export enum OrderingStatus {
  disabledIndefinitely = "service_disabled",
  enabled = "service_enabled",
  pausedByCashier = "paused_by_cashier",
  pausedByBrand = "paused_by_brand",
  pausedForOutsideWorkingHours = "paused_outside_working_hours",
  pausedForInactivity = "paused_for_inactivity",
  pausedForDisconnection = "paused_for_disconnection"
}
// interface IFormValues {
//   status: OrderingStatus;
//   reason?: IReason;
//   interval?: string;
// }

export const AvailableRequestsModal: React.FC<IProps &
  InjectedFormProps> = props => {
  const { t } = useTranslation("orderingStatus");
  const { open, onClose, selectedStatus } = props;

  React.useEffect(() => {
    wrapperRef.current?.scrollTo({ top: 800, behavior: "smooth" });
  }, [selectedStatus]);

  const OrderingStatuses: Array<ISelectItemData<
    OrderingStatus
  >> = React.useMemo(
    () => [
      {
        label: t(Strings.available),
        value: OrderingStatus.enabled
      },
      {
        label: t(Strings.unAvailable),
        value: OrderingStatus.pausedByCashier
      }
    ],
    []
  );
  // const { reasons } = useGetDisableReasons();
  // const rtl = useRtlClass({ rtl: "true" });

  // const OrderingDisabledReasons: Array<ISelectItemData<
  //   IReason
  // >> = React.useMemo(
  //   () =>
  //     (reasons || []).map(reason => ({
  //       value: reason,
  //       label: rtl ? reason.ar_text : reason.en_text
  //     })),
  //   [reasons]
  // );

  // I use this field value to determine whether to
  // show the reasons field -only shown when unavailable is selected-
  // or not
  // const { status: currentStatus } = useSelect(
  //   ({ form: { RequestsForm } }) =>
  //     RequestsForm && (RequestsForm.values as IFormValues),
  //   (l, r) => l && r && l.status !== r.status
  // );

  const dispatch = useDispatch();
  const intervalTimes: Array<ISelectItemData<string>> = React.useMemo(
    () => [
      {
        label: t(Strings.halfHour),
        value: 30 * 60 * 1000
      },
      {
        label: t(Strings.oneHour),
        value: 60 * 60 * 1000
      },
      {
        label: t(Strings.threeHours),
        value: 3 * 60 * 60 * 1000
      },
      {
        label: t(Strings.eightHours),
        value: 8 * 60 * 60 * 1000
      }
    ],
    []
  ) as any;
  const handleSubmit = React.useCallback(
    ({
      reason,
      status,
      interval
    }: {
      status: OrderingStatus;
      reason?: IReason;
      interval?: string;
    }) => {
      if (status === OrderingStatus.pausedByCashier) {
        dispatch(changeOrderingStatus({ status, reason, interval }));
        onClose();
      } else {
        dispatch(reEnableOrderingStatus({ status }));
        onClose();
      }
    },
    [onClose]
  );

  const wrapperRef = React.useRef<HTMLDivElement>(null);

  return (
    <Modal
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}
      open={open}
    >
      <div
        ref={wrapperRef}
        className={`${styles.wrapper} ${useRtlClass(styles)}`}
      >
        <img
          src={require("../../../assets/close.svg")}
          onClick={onClose}
          className={styles.close}
        />
        <div className={styles.separator} />
        <h1 className={styles.title}>{t(Strings.orderStatusTitle)}</h1>
        <div className={styles.separator} />
        <form
          onSubmit={props.handleSubmit(handleSubmit)}
          className={styles.form}
        >
          <Field
            component={RenderDropDownSelect}
            label={t(Strings.orderStatus)}
            name="status"
            className={styles.dropDownSelect}
            options={OrderingStatuses}
            validate={[required]}
          />

          <div className={styles.separator} />
          {selectedStatus === OrderingStatus.pausedByCashier && (
            <Field
              component={RenderDropDownSelect}
              label={t(Strings.offlineFor)}
              validate={[required]}
              name="interval"
              className={styles.secondMenuWrapper}
              options={intervalTimes}
            />
          )}
          {/*{currentStatus === OrderingStatus.pausedByCashier && (*/}
          {/*  <Field*/}
          {/*    component={RenderDropDownSelect}*/}
          {/*    label={t(Strings.reason)}*/}
          {/*    name="reason"*/}
          {/*    className={styles.secondMenuWrapper}*/}
          {/*    options={OrderingDisabledReasons}*/}
          {/*    validate={[required]}*/}
          {/*  />*/}
          {/*)}*/}
          <div className={styles.secondSeparator} />
          <button type="submit" className={styles.btn}>
            {t(Strings.confirm)}
          </button>
        </form>
      </div>
    </Modal>
  );
};

const valueSelector = formValueSelector("RequestsForm");

export default hot(module)(
  reduxForm({
    form: "RequestsForm",
    initialValues: {
      interval: "30 min"
    }
  })(
    connect((state: IRootReducerState) => {
      const selectedStatus = valueSelector(state, "status");
      return {
        selectedStatus
      };
    })(AvailableRequestsModal as any) as any
  ) as any
);
