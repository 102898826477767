import {
  addPoints,
  addPointsSuccess,
  addReceiptId,
  addReceiptValue,
  pointsValidation,
  cancelSelectingCountry,
  enterPhoneNumber,
  enterShopxId,
  invalidPhoneFormat,
  isRequired,
  notAPhoneNumber,
  phoneNumber,
  phoneNumberField,
  receiptValueField,
  searchForCountry,
  selectCountryTitle,
  sendingPoints,
  shopxId,
  tooShortField,
  next,
  addReceiptIdPlaceHolder,
  points,
  willBeAdded,
  back,
  chooseMethod,
  success,
  failed,
  selectBranchTitle,
  pointsManager,
  synced
} from "../../strings/ManagePoints";

export default {
  [willBeAdded]: "سيتم إضافة",
  [points]: "{{count}} نقطة",
  [phoneNumber]: "رقم الجوال",
  [shopxId]: "Koinz ID",
  [back]: "العودة",
  [chooseMethod]: "اختر وسيلة إضافة النقاط",
  [enterPhoneNumber]: "اكتب رقم جوال العميل",
  [enterShopxId]: "اكتب كود العميل",
  [addReceiptValue]: "اكتب قيمة فاتورة العميل",
  [addPoints]: "أضف النقاط",
  [addReceiptId]: "رقم الفاتورة",
  [addPointsSuccess]: "تم إرسال النقاط",
  [pointsManager]: "مدير النقاط",
  [synced]: "تمت المزامنة",
  [sendingPoints]: "جارٍ إرسال النقاط...",
  [cancelSelectingCountry]: "الغاء",
  [searchForCountry]: "البحث",
  [pointsValidation]: "لا يمكن أن تزيد عن 10,000",
  [selectCountryTitle]: "اختر دولة العميل",
  [invalidPhoneFormat]: "يجب أن يكون تابعاً للدولة المختارة",
  [notAPhoneNumber]: "رقم غير صحيح",
  [isRequired]: "حقل مطلوب",
  [tooShortField]: "القيمة المدخلة قصيرة جداً",
  [phoneNumberField]: "رقم الجوال",
  [receiptValueField]: "قيمة الفاتورة",
  [next]: "التالي",
  [addReceiptIdPlaceHolder]: "اكتب رقم الفاتورة",
  [success]: "نجاح",
  [failed]: "فشل",
  [selectBranchTitle]: "اختر الفرع"
};
