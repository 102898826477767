import { genericAction } from "../../constants";

const ACTION_TOGGLE_MODIFIER_ITEM = "ACTION_TOGGLE_MODIFIER_ITEM";
export const toggleModifierItemAction = genericAction(
  ACTION_TOGGLE_MODIFIER_ITEM
);

const ACTION_GET_ORDERING_STATUS = "ACTION_GET_ORDERING_STATUS";
export const getOrderingStatusAction = genericAction(
  ACTION_GET_ORDERING_STATUS
);

const ACTION_BRANCH_INFO = "ACTION_BRANCH_INFO";
export const getOrderingInfoAction = genericAction(ACTION_BRANCH_INFO);

export const ACTION_OFFLINE_DISMISS = "ACTION_OFFLINE_DISMISS";

const ACTION_GET_STORE_ORDERING_STATUS = "ACTION_GET_STORE_ORDERING_STATUS";

export const getStoreOrderingStatusAction = genericAction(
  ACTION_GET_STORE_ORDERING_STATUS
);

const ACTION_CHANGE_ORDERING_STATUS = "ACTION_CHANGE_ORDERING_STATUS";
export const changeOrderingStatusAction = genericAction(
  ACTION_CHANGE_ORDERING_STATUS
);

const ACTION_GET_MENU = "ACTION_GET_MENU";
export const getMenuAction = genericAction(ACTION_GET_MENU);

const ACTION_TOGGLE_CATS = "ACTION_TOGGLE_CATS";
export const toggleCategoryAction = genericAction(ACTION_TOGGLE_CATS);

const ACTION_TOGGLE_CAT_ITEMS = "ACTION_TOGGLE_CAT_ITEMS";
export const toggleItemAction = genericAction(ACTION_TOGGLE_CAT_ITEMS);

const ACTION_TOGGLE_MODIFIER_GROUP = "ACTION_TOGGLE_MODIFIER_GROUP";
export const toggleModifierGroupAction = genericAction(
  ACTION_TOGGLE_MODIFIER_GROUP
);

export const ACTION_SEARCH_FOR_MENU_ITEM = "ACTION_SEARCH_FOR_MENU_ITEM";

const ACTION_GET_ORDERS = "ACTION_GET_ORDERS";
export const getOrdersAction = genericAction(ACTION_GET_ORDERS);

export const ACTION_CHANGE_ORDER_TIMING_STATUS =
  "ACTION_CHANGE_ORDER_TIMING_STATUS";

export const ACTION_RECEIVE_NEW_ORDER = "ACTION_RECEIVE_NEW_ORDER";
export const ACTION_RECEIVE_ORDER_STATUS_CHANGE =
  "ACTION_RECEIVE_ORDER_STATUS_CHANGE";
export const ACTION_RECEIVE_ORDERING_STATUS_CHANGE =
  "ACTION_RECEIVE_ORDERING_STATUS_CHANGE";
export const ACTION_RECEIVE_PICKUP_STATUS_CHANGE =
  "ACTION_RECEIVE_PICKUP_STATUS_CHANGE";
export const ACTION_RECEIVE_DELIVERY_STATUS_CHANGE =
  "ACTION_RECEIVE_DELIVERY_STATUS_CHANGE";
export const ACTION_RECEIVE_STORE_ORDERING_STATUS_CHANGE =
  "ACTION_RECEIVE_STORE_ORDERING_STATUS_CHANGE";
export const ACTION_RECEIVE_CUSTOMER_ARRIVED =
  "ACTION_RECEIVE_CUSTOMER_ARRIVED";
export const ACTION_RECEIVE_BRANCH_AVERAGE_PREP_TIME_CHANGE =
  "ACTION_RECEIVE_BRANCH_AVERAGE_PREP_TIME_CHANGE";
export const ACTION_RECEIVE_DELIVERY_AVERAGE_PREP_TIME_CHANGE =
  "ACTION_RECEIVE_DELIVERY_AVERAGE_PREP_TIME_CHANGE";
const ACTION_RE_ENABLE_ORDERING_STATUS = "ACTION_RE_ENABLE_ORDERING_STATUS";
export const reEnableOrderingStatusAction = genericAction(
  ACTION_RE_ENABLE_ORDERING_STATUS
);

const ACTION_TOGGLE_ORDERING_STATUS = "ACTION_TOGGLE_ORDERING_STATUS";
export const toggleOrderingStatusAction = genericAction(
  ACTION_TOGGLE_ORDERING_STATUS
);

export const ACTION_RESET_LOCK_SCREEN_NOTIFICATION_COUNT =
  "ACTION_RESET_LOCK_SCREEN_NOTIFICATION_COUNT";
export const ACTION_INCREMENT_LOCK_SCREEN_NOTIFICATION_COUNT =
  "ACTION_INCREMENT_LOCK_SCREEN_NOTIFICATION_COUNT";

export const ACTION_AUTHENTICATE_SOCKET = "ACTION_AUTHENTICATE_SOCKET";
export const ACTION_CHANGE_SOCKET_STATUS = "ACTION_CHANGE_SOCKET_STATUS";

const ACTION_CHANGE_ORDER_STATUS = "ACTION_CHANGE_ORDER_STATUS";

export const changeOrderStatusAction = genericAction(
  ACTION_CHANGE_ORDER_STATUS
);

const ACTION_GET_BRANCH_AVG_PREP_TIME = "ACTION_GET_BRANCH_AVG_PREP_TIME";
export const getBranchAvgPrepTimeAction = genericAction(
  ACTION_GET_BRANCH_AVG_PREP_TIME
);

const ACTION_CHANGE_BRANCH_AVG_PREP_TIME = "ACTION_CHANGE_BRANCH_AVG_PREP_TIME";
export const changeBranchAvgPrepTimeAction = genericAction(
  ACTION_CHANGE_BRANCH_AVG_PREP_TIME
);

const ACTION_GET_STORE = "ACTION_GET_STORE";
export const getStoreAction = genericAction(ACTION_GET_STORE);

const ACTION_CONTACT_SUPPORT = "ACTION_CONTACT_SUPPORT";
export const contactSupportAction = genericAction(ACTION_CONTACT_SUPPORT);

const ACTION_CHANGE_ORDERS_GROUP_STATUS = "ACTION_CHANGE_ORDERS_GROUP_STATUS";

export const changeOrdersGroupStatusAction = genericAction(
  ACTION_CHANGE_ORDERS_GROUP_STATUS
);

export const ACTION_START_ORDER_EXIT_ANIMATION =
  "ACTION_START_ORDER_EXIT_ANIMATION";
export const ACTION_USER_SEEN_ORDER = "ACTION_USER_SEEN_ORDER";
export const ACTION_MARK_AS_OUT_FOR_DELIVERY =
  "ACTION_MARK_AS_OUT_FOR_DELIVERY";

export const ACTION_GET_STATUS_REASON = "ACTION_GET_STATUS_REASON";
export const getStatusReasonAction = genericAction(ACTION_GET_STATUS_REASON);
export const ACTION_GET_DELIVERY_REASONS = "ACTION_GET_DELIVERY_REASONS";
export const getDeliveryReasonsAction = genericAction(
  ACTION_GET_DELIVERY_REASONS
);
export const ACTION_REJECT_ORDER = "ACTION_REJECT_ORDER";
export const rejectOrderAction = genericAction(ACTION_REJECT_ORDER);

export const ACTION_CANCEL_ORDER = "ACTION_CANCEL_ORDER";
export const cancelOrderAction = genericAction(ACTION_CANCEL_ORDER);
const ACTION_CAN_CHOOSE_REASON = "ACTION_CAN_CHOOSE_REASON";
export const canChooseReasonAction = genericAction(ACTION_CAN_CHOOSE_REASON);

const ACTION_AVERAGE_DELIVERY_PREP_TIME = "ACTION_AVERAGE_DELIVERY_PREP_TIME";
export const deliveryAveragePreparationAction = genericAction(
  ACTION_AVERAGE_DELIVERY_PREP_TIME
);

const ACTION_GET_AVERAGE_DELIVERY_PREP_TIME =
  "ACTION_GET_AVERAGE_DELIVERY_PREP_TIME";
export const getDeliveryAveragePreparationAction = genericAction(
  ACTION_GET_AVERAGE_DELIVERY_PREP_TIME
);

const ACTION_CHANGE_DELIVERY_METHOD = "ACTION_CHANGE_DELIVERY_METHOD";
export const changeDeliveryMethod = genericAction(
  ACTION_CHANGE_DELIVERY_METHOD
);
