export const pointsManager = "pointsManager";
export const synced = "synced";
export const syncing = "syncing";
export const sendPoints = "sendPoints";
export const sendingPoints = "sendingPoints";
export const redeemGift = "redeemGift";
export const redeemingGift = "redeemingGift";
export const verifyingCode = "verifyingCode";
export const transactions = "transactions";
export const allTransactions = "allTransactions";
export const noTransaction = "noTransaction";
export const redeemed = "redeemed";
export const redeemedSuccessfully = "redeemedSuccessfully";
export const giftCode = "giftCode";
export const codeIsNotValid = "codeIsNotValid";
export const giftFor = "giftFor";
export const points = "points";
export const redeemption = "redeemption";
export const validateGiftCode = "validateGiftCode";
export const customerPhoneNumber = "customerPhoneNumber";
export const invoiceTotalOrder = "invoiceTotalOrder";
export const searchByPhoneNumber = "searchByPhoneNumber";
export const invoiceNumber = "invoiceNumber";
export const optional = "optional";
export const invoice = "invoice";
export const sentSuccessfully = "sentSuccessfully";
export const searchForCountryNameOrCode = "searchForCountryNameOrCode";
export const reset = "reset";
export const chooseCustomerCountry = "chooseCustomerCountry";
export const pointPOSMode = "pointPOSMode";