import reducer from "./rootReducer";
// import logger from "redux-logger"; // eslint-disable-line
import { applyMiddleware, compose, createStore, Middleware } from "redux";
// The redux dev tools.
import { composeWithDevTools } from "redux-devtools-extension"; // eslint-disable-line
// The redux middlewares.
import createSagaMiddleware from "redux-saga";
// The redux store enhancers.
import { persistStore } from "redux-persist";
import { Persistor } from "redux-persist/lib/types";
// Import the combined sagas.
import rootSaga from "./rootSaga";
import { loadingBarMiddleware } from "react-redux-loading-bar";
import { createMySocketMiddleware } from "../Pickup-App/pusher/socketMiddleware";
// import { optimisticUIMiddleware } from "./middleware/optimistic-ui";
// import {crashReporter} from "./middleware/rejection-capture";
// import { IAppStoreState } from "../types";
// import persistedStore, { initialState } from "./rootReducer";
// import { ISyncAppAction } from '../actions';
// export default () => {
//   const store = createStore<IAppStoreState, ISyncAppAction, any, any>(rootReducer, initialState);
//   applyMiddleware()
//   return store;
// }
//
// import thunk from 'redux-thunk';

// Import the axios instance.
// import axios from './axios/axios';

// The application's current environment.
export const isDev = process.env.NODE_ENV === "development";

// The middlewares to be applied.
const middlewares: Middleware[] = [];

// Instantiates the saga middleware.
const sagaMiddleware = createSagaMiddleware();
const socketMiddleWare = createMySocketMiddleware();

middlewares.push(
  socketMiddleWare,
  // optimisticUIMiddleware,
  sagaMiddleware
);
middlewares.push(
  loadingBarMiddleware({
    promiseTypeSuffixes: ["REQUESTED", "FULFILLED", "REJECTED"]
  })
);

// if (isDev) {
  // middlewares.push(logger);
  // } else {
  //   middlewares.push(crashReporter);
// }

// Combine the middlewares.
const middleware = applyMiddleware(...middlewares);

const enhancerParams = [middleware];

// Compose Enhancers (With/Without Dev Tools)
const enhancer = isDev
  ? composeWithDevTools(...enhancerParams)
  : compose<any>(...enhancerParams);

// Import the combined reducer.
export const store = createStore(reducer, enhancer);

let persistor: Persistor;

export const getPersistor = () => persistor;

sagaMiddleware.run(rootSaga);
export default () => {
  persistor = persistStore(store);
  return { persistor, store };
};
