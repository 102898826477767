import { IRootReducerState } from "../../redux-store/rootReducer";
import { useDispatch } from "react-redux";
import { getOS } from "../../../Utils/getOS";
import * as React from "react";
import loadable from "@loadable/component";
import { useInactive } from "../../../Utils/custom-hooks/use-inactive";
import { ENABLE_MOBILE } from "../../../env/environment";
import {
  setAppActiveState,
  listenToNetworkChange,
  logCurrentAppVersion
} from "../../redux-store/actions";
import { branchHasAnOrderingServiceEnabled } from "../../web-components/Login/SelectBranchInLogin/use-get-assigned-branches";
import { authenticateSocket } from "../../Pickup-App/redux/actions/socket-actions";
import { useSelect } from "../../helpers/use-select";
import LockScreen from "../../Pickup-App/components/LockScreen";

const WebApp = loadable(() => import("../../web-interface/web-app"));
const PickupApp = loadable(() => import("../../Pickup-App/pickupApp"));
const LoginPortal = loadable(() => import("../../web-components/Login"));
const MobileApp = loadable(() => import("../../mobile-interface/mobile-app"));

export enum CashierApps {
  pickup = "pickup",
  PCLoyalty = "loyalty",
  login = "login",
  mobileLoyalty = "mobile"
}

const AppsMap: { [x: string]: React.ComponentType } = {
  [CashierApps.PCLoyalty]: WebApp,
  [CashierApps.pickup]: PickupApp,
  [CashierApps.login]: LoginPortal,
  [CashierApps.mobileLoyalty]: MobileApp
};

const AppSelector: React.FC = () => {
  const { appToShow, authenticated, selectedBranchHasPickup } = useSelect(
    getAppToShowFromState
  );
  const AppropriatedApp = React.useMemo(() => AppsMap[appToShow], [appToShow]);
  const { inactive } = useInactive();
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(setAppActiveState(!inactive));
  }, [inactive]);
  React.useEffect(() => {
    dispatch(listenToNetworkChange());
  }, []);

  React.useEffect(() => {
    if (authenticated && selectedBranchHasPickup) {
      dispatch(authenticateSocket());
      dispatch(
        logCurrentAppVersion({
          app_version: localStorage.getItem("appVersion") || "Unknown"
        })
      );
    }
  }, [authenticated]);
  return (
    <>
      <LockScreen />
      <AppropriatedApp />
    </>
  );
};

export const getLoyaltyAppToShow = () => {
  const shouldShowMobile = ENABLE_MOBILE && getOS() === "Mobile";
  return shouldShowMobile ? CashierApps.mobileLoyalty : CashierApps.PCLoyalty;
};

export const getAppToShowFromState = (state: IRootReducerState) => {
  const {
    authReducer: {
      selected_branch,
      isLoggedIn,
      hasSelectedBranch,
      selected_app
    }
  } = state;
  const selectedBranchHasOrdering = Boolean(
    branchHasAnOrderingServiceEnabled(selected_branch)
  );

  const authenticated = isLoggedIn && hasSelectedBranch;

  const loyaltyAppToShow = getLoyaltyAppToShow();

  const appToShow = selectedBranchHasOrdering ? selected_app : loyaltyAppToShow;
  // Mobile interface had its own login

  // const loginToShow = shouldShowMobile ? CashierApps.login : CashierApps.login;
  return {
    appToShow: authenticated ? appToShow : CashierApps.login,
    authenticated,
    selectedBranchHasPickup: selectedBranchHasOrdering
  };
};

export default AppSelector;
