import instance from "./axios";
import * as requests from "./requests";
import { IBranchAvgPrepTime } from "./branchAveragePrepTime";

export const orderAveragePrepTimeApi = {
  get: (token: string, branch_id: string, order_id: string) =>
    instance.get<IBranchAvgPrepTime>(requests.orderAvgPrepTime(order_id), {
      headers: {
        token
      },
      params: { branch_id }
    }),

  put: (
    token: string,
    branch_id: string,
    order_id: string,
    data: IBranchAvgPrepTime
  ) =>
    instance.put(requests.orderAvgPrepTime(order_id), data, {
      headers: {
        token
      },
      params: { branch_id }
    })
};
