import { useProgressState } from "../../../../hooks/useProgressState";
import { useState, useEffect, useCallback } from "react";
import { IBranchAvgPrepTime } from "../../../../axios/branchAveragePrepTime";
import { orderAveragePrepTimeApi } from "../../../../axios/orderAveragePrepTime";
import { useSelect } from "../../../../../helpers/use-select";

export const useOrderAvgPrepTime = (orderId: string) => {
  const {
    setFailure,
    setLoading,
    setSuccess,
    ...progress
  } = useProgressState();

  const { loadingStatus, ...rest } = useSelect(
    state => state.branchAvgPrepTimeReducer,
    () => true
  );

  const [avgPrepTime, setAvgPrepTime] = useState<IBranchAvgPrepTime>(rest);
  const {
    token,
    selected_branch: { id }
  } = useSelect(state => state.authReducer);

  useEffect(() => {
    setLoading();

    orderAveragePrepTimeApi
      .get(token, id, orderId)
      .then(res => {
        setSuccess();
        setAvgPrepTime(res.data);
      })
      .catch(setFailure);
  }, []);

  const changeOrderAvgPrepTime = useCallback((data: IBranchAvgPrepTime) => {
    setLoading();
    orderAveragePrepTimeApi
      .put(token, id, orderId, {
        ...data,
        pickup_prep_min: data.pickup_prep_min || 1
      })
      .then(res => {
        setSuccess();
        setAvgPrepTime(res.data);
      })
      .catch(setFailure);
  }, []);
  return {
    avgPrepTime,
    changeOrderAvgPrepTime,
    ...progress
  };
};
