import header from "./header";

export default {
  code: "Order Code:",
  timer: "Customer Arrives After",
  zoneOrRegion: "Region/Zone",
  arrive: "Deliver to:",
  userName: "Customer Name",
  shipoxTitle:
    "Delivered by koinz (a courier will pick up and deliver the order to the customer)",
  total: "Total:",
  gotPickedUp: "Received",
  cash: "Pay on pickup",
  cashDelivery: "Cash on delivery",
  paymentMethod: "Payment Method",
  orderingMethod: "Ordering Method",
  reject: "Reject",
  accept: "Accept",
  orderTime: "Order at",
  visa: "VISA",
  cancel: "Cancel order",
  call: "Contact customer",
  orderSettings: "Order Settings",
  prevOrder: "Previous Orders",
  currentOrder: "Current order",
  contactSupport: "Contact Support",
  cancellingOrder: "Cancel order",
  confirmCancellation: "Confirm order cancellation",
  contactClient: "Contact customer",
  save: "Save",
  timeAdjustmentTo: "Modify time to:",
  preparationTime: "Modify prep time",
  clientArrivesAfter: "Customer Arrives After:",
  preparationButton: "Order ready?",
  price: "Price",
  typeOfRequest: "Type of request",
  number: "Qty.",
  comment: "Comment",
  commentOnRequest: "Comment on order",
  newOrders: "New Orders",
  lateOrders: "Require Attention",
  gift: "Gift",
  selectTheItemState: "Select the item state",
  soldOutForToday: "Sold out for today",
  or: "or",
  soldOut: "Sold out",
  orderType: "Order type",
  totalPrice: "Total Price",
  confirmRejectingOrder: "Confirm rejecting order",
  discount: "discount",
  totalPriceAfterDiscount: "Total",
  promo_applied: "Promo applied",
  rejectionReason: "You must choose a reason for rejection",
  warning: "Rejecting an order is irreversible",
  reason: "Reason:",
  receiveIt: "Pickup from branch",
  zone: "Region",
  street: "Street",
  buildingNumber: "Building",
  apartmentNumber: "Apartment",
  floor: "Floor",
  delivery: "Delivery",
  specialMark: "Special mark",
  pickup: "Receive from branch",
  readyButton: "Order is ready?",
  readyDeliveryButton: "Out for delivery?",
  markAsDelivered: "Delivered?",
  back: "Back",
  deliveryCost: "Delivery",
  giftRedemptionCode: "Gift redemption code:",
  takeaway: "Takeaway",
  dineIn: "Dine In",
  tableNumber: "Table number",
  ...header
};
