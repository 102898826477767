import { getOrderingInfoAction } from "../../constants/actions";
import { payload, action } from "ts-action";
import { IBranchOrdering } from "../../../data-model/types";

export const getOrderingInfo = action(
  getOrderingInfoAction.requested,
  payload<{ branch_id: string }>()
);
export const getOrderingInfoSuccess = action(
  getOrderingInfoAction.fulfilled,
  payload<IBranchOrdering>()
);
export const getOrderingInfoFailure = action(getOrderingInfoAction.rejected);
