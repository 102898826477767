import { reducer, on } from "ts-action";
import {
  MenuEntityAvailability,
  MenuEntitySwitching,
  toggleCategory
} from "../actions/menuActions";
import { toggleCategoryAction } from "../../constants/actions";
import { withLoadingReducer } from "./withLoadingState";
import { ICategory } from "./lib";

export const categoryItemReducer = withLoadingReducer<ICategory>(
  reducer<ICategory>(
    [
      on(toggleCategory, (state, { payload: { status } }) => ({
        ...state,
        enabled: Number(!state.enabled),
        status:
          status === MenuEntitySwitching.enabled
            ? MenuEntityAvailability.enabled
            : MenuEntityAvailability[status]
      }))
    ],
    {
      ar_name: "",
      en_name: "",
      enabled: 0,
      id: "",
      status: MenuEntityAvailability.enabled
    }
  ),
  toggleCategoryAction
);
