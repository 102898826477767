import { MenuEntitySwitching } from "../../../redux/actions/menuActions";
import React, { useCallback } from "react";
import Modal from "@material-ui/core/Modal";
import * as styles from "./styles.module.sass";
import { hot } from "react-hot-loader";
import { useTranslation } from "../../../hooks/useTranslation";
import * as Strings from "../../../i18n/strings/newOrder";

export type handleSelectItemFunctionType = (
  status: MenuEntitySwitching
) => void;
// "all", "single" are specified for modifier items status change, change could be applied to all similar mod items or to just one
export type handleSelectOneOrAllFunctionType = (
  grouping: "all" | "single"
) => void;

interface IProps {
  onSelect: handleSelectItemFunctionType;
  open: boolean;
  closeModal: () => void;
}

export const MarkEntitySoldOutContext = React.createContext<{
  open: (func: handleSelectItemFunctionType) => void;
  openModifierGroup: (func: handleSelectItemFunctionType) => void;
  openModifierItem: (func: handleSelectItemFunctionType) => void;
  openDisableEnableAllOrOne: (
    numberOfItems: number,
    actionType: "disable" | "enable",
    func: handleSelectOneOrAllFunctionType,
    ) => void;
}>({
  open: (func: handleSelectItemFunctionType) => null,
  openModifierGroup: (func: handleSelectItemFunctionType) => null,
  openModifierItem: (func: handleSelectItemFunctionType) => null,
  openDisableEnableAllOrOne: (
    numberOfItems,
    actionType,
    func: handleSelectOneOrAllFunctionType,
  ) => null
});

const Component: React.FC<IProps> = ({ onSelect, open, closeModal }) => {
  const { t } = useTranslation("newOrder");

  const handleSelect = useCallback(
    (status: MenuEntitySwitching) => {
      onSelect(status);
      closeModal();
    },
    [onSelect]
  );

  const handleClickIndefinitely = useCallback(
    () => handleSelect(MenuEntitySwitching.sold_out_indefinitely),
    [handleSelect]
  );
  const handleClickAllDay = useCallback(
    () => handleSelect(MenuEntitySwitching.sold_out_all_day),
    [handleSelect]
  );

  return (
    <Modal
      open={open}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <div className={styles.wrapper}>
        <img
          src={require("../../../assets/close.svg")}
          onClick={closeModal}
          className={styles.close}
        />
        <h4 className={styles.title}>{t(Strings.selectTheItemState)}</h4>
        <div className={styles.optionsWrapper}>
          <button className={styles.optionButton} onClick={handleClickAllDay}>
            {t(Strings.soldOutForToday)}
          </button>
          <span className={styles.buttonsSeparator}>{t(Strings.or)}</span>
          <button
            className={styles.optionButton}
            onClick={handleClickIndefinitely}
          >
            {t(Strings.soldOut)}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export const MarkItemSoldOutPopup = hot(module)(Component);
