import { payload, action } from "ts-action";
import * as actions from "../../constants/actions";
import { IOrder, OrderTimingStatus } from "../reducers/lib";
import { OrderStatus, IReasons } from "../../constants/types";
import { optAction } from "./lib";
import { OrderStatusForReason } from "../../axios/getStatusReason";

export const getOrders = action(
  actions.getOrdersAction.requested,
  payload<OrderStatus[]>()
);
export const getOrdersFailure = action(actions.getOrdersAction.rejected);
export const getOrdersSuccess = action(
  actions.getOrdersAction.fulfilled,
  payload<IOrder[]>()
);
export const changeOrderTimingStatus = action(
  actions.ACTION_CHANGE_ORDER_TIMING_STATUS,
  payload<{ id: string; newStatus: OrderTimingStatus }>()
);

export const changeOrderStatus = optAction(
  actions.changeOrderStatusAction.requested,
  payload<{ id: string; newStatus: OrderStatus }>()
);

export const changeOrderStatusSuccess = action(
  actions.changeOrderStatusAction.fulfilled,
  payload<IOrder>()
);

export const changeOrderStatusFailure = action(
  actions.changeOrderStatusAction.rejected,
  payload<{ id: string; newStatus: OrderStatus }>()
);

export const changeOrdersGroupStatus = action(
  actions.changeOrdersGroupStatusAction.requested,
  payload<{
    orderIds: string[];
    status: OrderStatus.seen | OrderStatus.received;
  }>()
);
export const changeOrdersGroupStatusSuccess = action(
  actions.changeOrdersGroupStatusAction.fulfilled,
  payload<{
    orderIds: string[];
    status: OrderStatus.seen | OrderStatus.received;
  }>()
);
export const changeOrdersGroupStatusFailure = action(
  actions.changeOrdersGroupStatusAction.rejected,
  payload<{
    orderIds: string[];
    status: OrderStatus.seen | OrderStatus.received;
  }>()
);

export const startOrderExitAnimation = action(
  actions.ACTION_START_ORDER_EXIT_ANIMATION,
  // can be an array of order ids that's okay
  payload<{
    order_id: string;
    status: OrderStatus;
  }>()
);

export const seeUserOrder = action(
  actions.ACTION_USER_SEEN_ORDER,
  payload<{ order_id: string }>()
);

export const markAsOutForDelivery = action(
  actions.ACTION_MARK_AS_OUT_FOR_DELIVERY,
  payload<{ order_id: string; branch_id: string }>()
);

export const getStatusReason = action(
  actions.getStatusReasonAction.requested,
  payload<{ order_status: OrderStatusForReason }>()
);
export const getStatusReasonSuccess = action(
  actions.getStatusReasonAction.fulfilled,
  payload<IReasons[]>()
);
export const getStatusReasonFailure = action(
  actions.getStatusReasonAction.rejected,
  payload<Error>()
);

export const getDeliveryReasons = action(
  actions.getDeliveryReasonsAction.requested,
  payload<{ order_status: OrderStatusForReason }>()
);
export const getDeliveryReasonsSuccess = action(
  actions.getDeliveryReasonsAction.fulfilled,
  payload<IReasons[]>()
);
export const getDeliveryReasonsFailure = action(
  actions.getDeliveryReasonsAction.rejected,
  payload<Error>()
);

export const cancelOrder = optAction(
  actions.cancelOrderAction.requested,
  payload<{ order_id: string; branch_id: string; reason_id?: number }>()
);

export const cancelOrderSuccess = action(
  actions.cancelOrderAction.fulfilled,
  payload<string>()
);

export const cancelOrderFailure = action(
  actions.cancelOrderAction.rejected,
  payload<Error>()
);

export const rejectOrders = optAction(
  actions.rejectOrderAction.requested,
  payload<{ order_id: string; branch_id: string; reason_id?: number }>()
);

export const rejectOrderSuccess = action(
  actions.rejectOrderAction.fulfilled,
  payload<string>()
);

export const rejectOrderFailure = action(
  actions.rejectOrderAction.rejected,
  payload<Error>()
);

export const canChooseReason = action(
  actions.canChooseReasonAction.requested,
  payload<{ orderId: string }>()
);

export const canChooseReasonSuccess = action(
  actions.canChooseReasonAction.fulfilled,
  payload<boolean>()
);

export const canChooseReasonFailure = action(
  actions.canChooseReasonAction.rejected,
  payload<Error>()
);
