import axios from "./axios";
import * as request from "./requests";

export const markAsOutForDeliveryAPI = (
  token: string,
  data: { order_id: string; branch_id: string }
) => {
  return axios.put(request.markAsOutForDelivery, data, {
    headers: {
      token
    }
  });
};
