import { reducer, on } from "ts-action";
import {
  getBranchAvgPrepTimeSuccess,
  changeBranchAvgPrepTime
} from "../actions/branchAvgPrepTimeActions";
import { IBranchAvgPrepTime } from "../../axios/branchAveragePrepTime";
import { withLoadingReducer, ILoadingState } from "./withLoadingState";
import { getBranchAvgPrepTimeAction } from "../../constants/actions";
import { optimistic, OptimisticState } from "redux-optimistic-ui";
import { Reducer } from "redux";
import { receiveBranchAveragePrepTimeChange } from "../actions/socket-actions";

export const branchAvgPrepTimeReducer = optimistic(
  withLoadingReducer<IBranchAvgPrepTime>(
    reducer(
      [
        on(
          {
            getBranchAvgPrepTimeSuccess,
            changeBranchAvgPrepTime,
            receiveBranchAveragePrepTimeChange
          },
          (s, action) => action.payload
        )
      ],
      { pickup_prep_max: 1, pickup_prep_min: 0 }
    ),
    getBranchAvgPrepTimeAction
  )
) as Reducer<OptimisticState<IBranchAvgPrepTime & ILoadingState>>;
