import { call, put, select, takeLatest } from "redux-saga/effects";
import { initOrderingMenuDB } from "../../db";
import * as constants from "../../../constants";
import { openOrderingMenuDBFailure, openOrderingMenuDBSuccess } from "../actions/orderingMenuDBActions";
import { OrderingMenuDB } from "../../db/orderingMenuDb";
import { selectIsLoggedIn } from "../../../redux-store/selectors";

function* openOrderingMenuDBSaga() {
  try {
    const isLoggedIn: boolean = yield select(selectIsLoggedIn);
    if (isLoggedIn) {
      const orderingMenuDB: OrderingMenuDB = yield call(initOrderingMenuDB);
      if (!orderingMenuDB.isOpen()) {
        yield orderingMenuDB.open();
      }
      yield put(openOrderingMenuDBSuccess(orderingMenuDB));
    } else {
      yield put(openOrderingMenuDBFailure(new Error("user not logged in")));
    }
  } catch (error) {
    yield put(openOrderingMenuDBFailure(error));
  }
}

export function* watchOpenOrderingMenuDBSaga() {
  yield takeLatest(constants.openOrderingMenuDBAction.requested, openOrderingMenuDBSaga);
}
