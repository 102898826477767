import * as Strings from "../strings/loyalty";
export default {
  [Strings.pointsManager]: "Points manager",
  [Strings.points]: "Points",
  [Strings.synced]: "Synced",
  [Strings.syncing]: "Syncing...",
  [Strings.allTransactions]: "All transactions",
  [Strings.codeIsNotValid]: "Code is not valid",
  [Strings.customerPhoneNumber]: "Customer phone number",
  [Strings.giftCode]: "Gift code",
  [Strings.invoiceNumber]: "Invoice number",
  [Strings.optional]: "Optional",
  [Strings.invoice]: "Invoice",
  [Strings.giftFor]: "Gift for",
  [Strings.redeemingGift]: "Redeeming gift ...",
  [Strings.redeemedSuccessfully]: "Gift redeemed successfully!",
  [Strings.redeemption]: "Redeemption",
  [Strings.verifyingCode]: "Validating gift code ...",
  [Strings.invoiceTotalOrder]: "Invoice total order number",
  [Strings.noTransaction]: "No transaction found",
  [Strings.redeemGift]: "Redeem gift",
  [Strings.redeemed]: "Redeemed",
  [Strings.searchByPhoneNumber]: "Search by phone number",
  [Strings.sentSuccessfully]: "Points sent successfully!",
  [Strings.searchForCountryNameOrCode]: "Search for country name, code...",
  [Strings.chooseCustomerCountry]: "Choose country",
  [Strings.reset]: "Reset",
  [Strings.sendPoints]: "Send points",
  [Strings.sendingPoints]: "Sending points...",
  [Strings.transactions]: "Transactions",
  [Strings.validateGiftCode]: "Validate gift code",
  [Strings.pointPOSMode]: "Point",
};
