import axios from "./axios";
import * as requests from "./requests";
import { OrderType } from "../constants/types";
import { DeliveryMethods } from "./changeDeliveryMethod";

export enum PaymentMethods {
  cash = "cash",
  online_payment_general = "online_payment_general",
  online_payment_apple_pay = "online_payment_apple_pay"
}
export interface IPromotion {
  customers_file_path: string;
  end_time: number;
  discount_value: number;
  created_at: number;
  promo_code: string;
  type: string;
  title: string;
  max_discount_value: number;
  max_usage_times: number;
  min_threshold: number;
  start_time: number;
  updated_at: number;
  user_id: string;
  id: string;
  desc: string;
}
export interface IGetOrderDetailsResponse {
  items: IOrderDetailsItem[];
  total_invoices: number;
  payment_method: PaymentMethods;
  total_invoices_without_promo_code?: number;
  comment?: string;
  promotion?: IPromotion;
  order_type: OrderType;
  delivery_method?: DeliveryMethods;
}

export interface IGetDeliveryOrderDetails extends IGetOrderDetailsResponse {
  sub_district_ar_name: string;
  sub_district_en_name: string;
  district_ar_name: string;
  district_en_name: string;
  address: IAddress;
  order_type: OrderType.delivery;
}

export interface IOrderDetailsItem {
  id: string;
  redemption_code: any;
  en_name?: string;
  ar_name?: string;
  image: string;
  price: number;
  quantity: number;
  is_gift?: 1;
  modifiers_group: IOrderDetailsModifiersGroup[];
  comment?: string;
  dispatched_to_third_party_delivery?: number;
  category_en_name?: string;
  category_ar_name?: string;
}

export interface IOrderDetailsModifiersGroup {
  en_name?: string;
  ar_name?: string;
  modifiers: IModifier[];
  id: string;
}

interface IModifier {
  name: string;
  price: number;
  en_name: string;
  ar_name: string;
  id: string;
}

export const getOrderDetails = (
  token: string,
  branch_id: string,
  order_id: string
) => {
  return axios.get<IGetOrderDetailsResponse>(
    requests.getOrderDetails(order_id),
    {
      params: {
        branch_id
      },
      headers: {
        token
      }
    }
  );
};

export interface IAddress {
  street: string;
  more_info: string;
  id: string;
  type: string;
  floor: string;
  building: string;
  apartment: string;
}
