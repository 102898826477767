import ManagePoints from "./ManagePoints";
import RedeemGifts from "./RedeemGifts";
import {
  CURRENT_LANG,
  MANAGE_POINTS,
  pointsLog,
  REDEEM_GIFTS,
  redeemLog
} from "../strings/App";
import Login from "./Login";
import Sync from "./Sync";
import newOrder from "../../Pickup-App/i18n/en/newOrder";
import orderingSettings from "../../Pickup-App/i18n/en/orderingSettings";
import navigation from "../../Pickup-App/i18n/en/navigation";
import logs from "./logs";
import contactshopx from "./contactshopx";
import orderingStatus from "../../Pickup-App/i18n/en/orderingStatus";
import switchAccount from "./switchAccount";
import logout from "./logout";
import axios from "./axios";
import navbar from "./navbar";
import selectBranch from "./selectBranch";
import VerifyPasswordModal from "./VerifyPasswordModal";
import header from "../../Pickup-App/i18n/en/header";
import loyalty from "../../Pickup-App/i18n/en/loyalty";
import statusNavigator from "../../Pickup-App/i18n/en/statusNavigator";
import pastOrdersStatues from "../../Pickup-App/i18n/en/pastOrdersStatues";
import SearchForProduct from "../../Pickup-App/i18n/en/SearchForProduct";
import menuItem from "../../Pickup-App/i18n/en/menuItem";
import StoreStatus from "../../Pickup-App/i18n/en/StoreStatus";

import * as menu from "../../Pickup-App/i18n/en/menu";
import lockscreen from "../../Pickup-App/i18n/en/lockscreen";

export const en = {
  translations: {
    [MANAGE_POINTS]: "Manage Points",
    [REDEEM_GIFTS]: "Redeem Gifts",
    [CURRENT_LANG]: "تغيير للغة العربية",
    [pointsLog]: "Points Log",
    [redeemLog]: "Gifts Log"
  },
  [MANAGE_POINTS]: ManagePoints,
  [REDEEM_GIFTS]: RedeemGifts,
  Login,
  Sync,
  axios,
  logs,
  contactshopx,
  statusNavigator,
  header,
  loyalty,
  switchAccount,
  newOrder,
  orderingSettings,
  logout,
  navbar,
  selectBranch,
  navigation,
  VerifyPasswordModal,
  orderingStatus,
  menu,
  pastOrdersStatues,
  SearchForProduct,
  menuItem,
  StoreStatus,
  lockscreen
};
