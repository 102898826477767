import { takeLatest, select, call } from "redux-saga/effects";
import { getBranchAvgPrepTimeAction } from "../../constants/actions";
import { handleOptimism } from "./handleOptimism";
import {
  getBranchAvgPrepTimeSuccess,
  getBranchAvgPrepTimeFailure
} from "../actions/branchAvgPrepTimeActions";
import {
  selectToken,
  selectCashierBranchId
} from "../../../redux-store/selectors";
import { branchAveragePrepTimeApi } from "../../axios/branchAveragePrepTime";

function* getBranchAvgPrepTimeSaga() {
  const token = yield select(selectToken);
  const branch_id = yield select(selectCashierBranchId);
  const res = yield call(branchAveragePrepTimeApi.get, token, branch_id);
  return res.data;
}

export function* watchGetBranchAvgPrepTime() {
  yield takeLatest(
    getBranchAvgPrepTimeAction.requested,
    handleOptimism(getBranchAvgPrepTimeSaga, {
      success: getBranchAvgPrepTimeSuccess,
      failure: getBranchAvgPrepTimeFailure
    })
  );
}
