import axios from "./axios";
import * as requests from "./requests";

export const getBranchByIdAPI = (token: string, branch_id: string) => {
  return axios.get(requests.getBranchOrderingInfo(branch_id), {
    headers: {
      token
    }
  });
};
