import * as Strings from "../strings/header";
export default {
  [Strings.availableTitle]: "الطلب متاح",
  [Strings.unAvailableTitle]: "الطلب غير متاح",
  [Strings.avgTime]: "متوسط وقت التحضير",
  [Strings.contactSupport]: "اطلب الاتصال بالدعم",
  [Strings.label]: "الان علي تطبيق:",
  [Strings.from]: "من",
  [Strings.to]: "إلى",
  [Strings.minute]: "دقيقة",
  [`${Strings.minute}_ 1`]: "دقيقة",
  [`${Strings.minute}_ 2`]: "دقيقتان",
  [`${Strings.minute}_ 3`]: "{{count}} دقائق",
  [`${Strings.minute}_ 4`]: "{{count}} دقيقة",
  [Strings.save]: "حفظ التغييرات",
  [Strings.pointingSystem]: "مدير النقاط",
  [Strings.requestsSystem]: "مدير الطلبات",
  [Strings.current]: "(الحالى)",
  [Strings.transactions]: "عمليه",
  [Strings.contactSupportTitle]:
    " قم بتأكيد طلب المساعدة و سيقوم فريق الدعم باالاتصال بك علي الفور",
  [Strings.min]: "د",
  [Strings.time]: "الوقت",
  [Strings.pickupAverage]: "وقت التحضير",
  [Strings.deliveryAverage]: "وقت التوصيل",
  [Strings.koinz]: "كوينز"
};
