import axios, { AxiosError, AxiosResponse } from "axios";
import { logout } from "../redux-store/actions/logout-actions";
import { store } from "../redux-store/configureStore";
import { switchCashier, login } from "./requests";
import { notify } from "react-notify-toast";
import i18n from "../../src/i18n/i18n";
import { checkInternet } from "../i18n/strings/axios";

const constructMessageFromError = (err: AxiosError) => {
  try {
    const errors = err?.response?.data?.validation_errors;
    let fullMessage = "";
    if (errors) {
      Object.keys(errors).forEach(errorKey => {
        fullMessage += errors[errorKey].join("\n");
      });
      return fullMessage;
    }
    return i18n.t(checkInternet, {
      ns: "axios"
    });
  } catch {
    return i18n.t(checkInternet, {
      ns: "axios"
    });
  }
};

const handleSuccess = (
  res: AxiosResponse
): AxiosResponse | Promise<AxiosResponse> => res;
const handleError = (error: AxiosError) => {
  const isSwitchingAccount: boolean =
    error.config.url === process.env.ENDPOINT + switchCashier;
  const isLogin = error.config.url === process.env.ENDPOINT + login;
  const myError = error.response as AxiosResponse;
  if (!myError) {
    notify.hide();
    notify.show(
      i18n.t(checkInternet, {
        ns: "axios"
      }),
      "error"
    );
    throw error;
  }
  if (error?.response?.status === 400) {
    notify.show(constructMessageFromError(error), "error");
  }
  if (
    (error.response as AxiosResponse).status === 401 &&
    !isSwitchingAccount &&
    !isLogin
  ) {
    store.dispatch(logout());
  }
  throw error;
};

export const createAxiosInstance = (baseURL: string) => {
  const instance = axios.create({
    baseURL,
    headers: {
      "Content-Type": "application/json"
    }
  });
  instance.interceptors.response.use(handleSuccess, handleError);
  return instance;
};

export default createAxiosInstance(process.env.ENDPOINT as string);
