import { Middleware, MiddlewareAPI, Dispatch } from "redux";
import { initSocket } from "./socket";
import { ACTION_AUTHENTICATE_SOCKET } from "../constants/actions";
import { union } from "ts-action";
import Pusher from "pusher-js";
import * as actions from "../redux/actions/socket-actions";
import { SocketConnectionStatus } from "../constants/types";
import branchChannelEventsActions from "./branchChannelEventsActions";
import storeChannelEventsActions from "./storeChannelEventsActions";
import { IRootReducerState } from "../../redux-store/rootReducer";
import { logoutAction } from "../../constants";
import { changeNetworkState } from "../../redux-store/actions";
import { ON_OFFLINE, ON_ONLINE } from "../../constants/netEvents";

export const delay = (timeout = 100) => {
  return new Promise(res => setTimeout(() => res(), timeout));
};

const disconnectSocket = async (
  socket: Pusher.Pusher,
  storeAPI: MiddlewareAPI<any, IRootReducerState>
) => {
  const { cashier_store_id, selected_branch } = storeAPI.getState().authReducer;
  socket?.unbind_all();
  socket?.connection?.unbind_all();
  socket?.connection?.disconnect();
  await delay();

  socket?.unsubscribe(`presence-cashier_orders.${cashier_store_id}`);
  await delay();

  socket?.unsubscribe(`presence-store-pickup-status.${selected_branch.id}`);
  await delay();

  socket?.disconnect();
  await delay();
};

const configureSocket = (
  socket: Pusher.Pusher,
  storeAPI: MiddlewareAPI<any, IRootReducerState>,
  branch_id: string
) => {
  socket.connection.bind(
    "state_change",
    (states: { current: SocketConnectionStatus }) => {
      switch (states.current) {
        case SocketConnectionStatus.connected:
        case SocketConnectionStatus.connecting:
          storeAPI.dispatch(changeNetworkState(ON_ONLINE));
          break;
        case SocketConnectionStatus.unavailable:
        case SocketConnectionStatus.failed:
          storeAPI.dispatch(changeNetworkState(ON_OFFLINE));
      }
      storeAPI.dispatch(
        actions.changeConnectionStatus({ status: states.current })
      );
    }
  );

  const store_id = storeAPI.getState().authReducer.cashier_store_id;
  const branchChannel = socket.subscribe(
    `presence-cashier_orders.${branch_id}`
  );
  const storeChannel = socket.subscribe(
    `presence-store-pickup-status.${store_id}`
  );

  // const channel = socket.subscribe(`my-channel`);

  branchChannelEventsActions.forEach(eventMeta => {
    branchChannel.bind(eventMeta.label, data => {
      storeAPI.dispatch(eventMeta.actionCreator(data));
    });
  });
  storeChannelEventsActions.forEach(eventMeta => {
    storeChannel.bind(eventMeta.label, data => {
      storeAPI.dispatch(eventMeta.actionCreator(data));
    });
  });

  return {
    branchChannel,
    storeChannel
  };
};

const actionTypes = union(actions);
type SocketAction = typeof actionTypes;

export const createMySocketMiddleware = (): Middleware<
  {},
  IRootReducerState
> => {
  let socket: Pusher.Pusher;
  let channels: { branchChannel: Pusher.Channel; storeChannel: Pusher.Channel };
  return storeAPI => {
    return (next: Dispatch<SocketAction>) => async (action: SocketAction) => {
      if (!channels && action.type === ACTION_AUTHENTICATE_SOCKET) {
        const { token, selected_branch } = storeAPI.getState().authReducer;
        socket = initSocket(token, selected_branch.id);
        channels = configureSocket(socket, storeAPI, selected_branch.id);
        return;
      }
      if (action.type === logoutAction.requested) {
        if (socket && channels) {
          channels?.branchChannel?.unbind_all();
          channels?.storeChannel?.unbind_all();
          await disconnectSocket(socket, storeAPI);
        }
      }
      return next(action);
    };
  };
};
