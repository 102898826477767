import { IPendingSyncActivity, SyncOperation, SyncService } from "../../types";
import { ICustomer } from "../types";

export class PendingSyncActivity implements IPendingSyncActivity {
  public customer: ICustomer | string;
  public data: {
    id: string;
    shopxId?: string;
    phoneNumber?: string;
    countryCode?: string;
    receipt?: number;
    receipt_code?: string;
    call_center?: number | undefined;
    branch_id?: string;
  };
  public operation: SyncOperation;
  public service: SyncService;
  public timestamp: number;
  constructor(data: IPendingSyncActivity) {
    this.customer = data.customer;
    this.data = data.data;
    this.operation = data.operation;
    this.service = data.service;
    this.timestamp = data.timestamp;
  }
}
