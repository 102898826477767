export const userName = "userName";
export const code = "code";
export const timer = "timer";
export const arrive = "arrive";
export const total = "total";
export const cash = "cash";
export const visa = "visa";
export const cashDelivery = "cashDelivery";
export const paymentMethod = "paymentMethod";
export const orderingMethod = "orderingMethod";
export const shipoxTitle = "shipoxTitle";
export const accept = "accept";
export const preparationButton = "preparationButton";
export const readyButton = "readyButton";
export const reject = "reject";
export const orderTime = "orderTime";
export const cancel = "cancel";
export const gotPickedUp = "gotPickedUp";
export const call = "call";
export const orderSettings = "orderSettings";
export const prevOrder = "prevOrder";
export const currentOrder = "currentOrder";
export const contactSupport = "contactSupport";
export const cancellingOrder = "cancellingOrder";
export const confirmCancellation = "confirmCancellation";
export const contactClient = "contactClient";
export const timeAdjustmentTo = "timeAdjustmentTo";
export const preparationTime = "preparationTime";
export const price = "price";
export const typeOfRequest = "typeOfRequest";
export const number = "number";
export const clientArrivesAfter = "clientArrivesAfter";
export const comment = "comment";
export const commentOnRequest = "commentOnRequest";
export const newOrders = "newOrders";
export const lateOrders = "lateOrders";
export const gift = "gift";
export const selectTheItemState = "selectTheItemState";
export const soldOutForToday = "soldOutForToday";
export const or = "or";
export const soldOut = "soldOut";
export const orderType = "orderType";
export const totalPrice = "totalPrice";
export const confirmRejectingOrder = "confirmRejectingOrder";
export const back = "back";
export const discount = "discount";
export const deliveryCost = "deliveryCost";
export const totalPriceAfterDiscount = "totalPriceAfterDiscount";
export const promo_applied = "promo_applied";
export const rejectionReason = "rejectionReason";
export const warning = "warning";
export const receiveIt = "receiveIt";
export const zone = "zone";
export const zoneOrRegion= "zoneOrRegion"
export const street = "street";
export const buildingNumber = "buildingNumber";
export const apartmentNumber = "apartmentNumber";
export const floor = "floor";
export const specialMark = "specialMark";
export const reason = "reason";
export const delivery = "delivery";
export const pickup = "pickup";
export const readyDeliveryButton = "readyDeliveryButton";
export const markAsDelivered = "markAsDelivered";
export const giftRedemptionCode = "giftRedemptionCode";
export const takeaway = "takeaway";
export const dineIn = "dineIn";
export const tableNumber = "tableNumber";
export * from "./header";
