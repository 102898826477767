const ENDPOINTS = {
  development: "https://api-core.koinz.app",
  test: "https://api-core.koinz.app",
  production: "https://api-core.koinz.app"
};
const ADMIN_ENDPOINTS = {
  development: "https://api-admin.koinz.app",
  test: "https://api-admin.koinz.app",
  production: "https://api-admin.koinz.app"
};
const PICKUP_ENDPOINTS = {
  development: "https://api-order.koinz.app",
  test: "https://api-order.koinz.app",
  production: "https://api-order.koinz.app"
};
const imagesURL = "https://api-core.koinz.app";
const VERSION_FIELD = "cashier";
const ENABLE_MOBILE = false;
const PUSHER_API_KEY = "206d1b6767d579c3cac6";
module.exports = {
  ENDPOINTS,
  ENABLE_MOBILE,
  VERSION_FIELD,
  imagesURL,
  PUSHER_API_KEY,
  ADMIN_ENDPOINTS,
  PICKUP_ENDPOINTS
};
